.blackBtnBG {
  border-radius: 12px;
  border: 1px solid transparent;
  background: linear-gradient(#3e4057, #161721) padding-box,
    linear-gradient(to bottom, #51525f, #030304) border-box;
  // line-height: 31px;
}

.commonBtn {
  min-height: 35px;
  // height: 35px;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 33px;

  color: #fff;
  text-align: center;
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  // line-height: 24px; /* 200% */
  text-transform: uppercase;
  display: inline-block;

  text-decoration: none;
  transition: 300ms;
  border-radius: 12px;
  transition-duration: 500ms;
  transition-property: all;
  border: none;
  svg {
    margin-right: 10px;
  }
  &:focus {
    scale: 0.98;
  }
  &:hover,
  &.active {
    color: $white;
    text-decoration: none;
    background-color: #e9a147 !important;
    transform: translateY(-4px);
    //  bottom: 32px;
    transition-duration: 500ms;
    transition-property: all;
  }

  &.blackBtnBG {
    line-height: 32px;
  }

  &.filledBtn {
    background-color: $buttonColorOne;
    color: #000;
  }

  &.borderBtn {
    border: 1px solid $buttonColorOne;
  }
  &.yellowBtn {
    background-color: $yellowColor;
    color: #55657e;
  }

  &.loginBtn {
    border: none;
  }

  &.casinoBtn {
    // background: url("../assets/images/kjb/common/casinoBtnBg.png");
    // width: 110px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #f1980c;
    transition-duration: 500ms;
    transition-property: all;
    &:hover {
      color: $white !important;
      box-shadow: inset 500px 0 0 0 #f41010;
      transition: color 0.7s ease-in-out, box-shadow 0.8s ease-in-out;
      transition-duration: 500ms;
      transition-property: all;
      svg {
        fill: $white;
      }
    }
    svg {
      margin-right: 5px;
    }
  }
  &.sportsBtn {
    // background: url("../assets/images/kjb/common/sportsBtnBg.png");
    // width: 90px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #f1980c;
    transition-duration: 500ms;
    transition-property: all;
    &:hover {
      color: $white !important;
      box-shadow: inset 500px 0 0 0 #f41010;
      transition: color 0.7s ease-in-out, box-shadow 0.8s ease-in-out;
      transition-duration: 500ms;
      transition-property: all;
      svg {
        fill: $white;
      }
    }
    svg {
      margin-right: 5px;
    }
  }

  &.promotionsBtn {
    border-radius: 12px;
    display: block;
    background: url("../assets/images/kjb/common/bonus-btn-bg.png");
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
  &.bonusBtn {
    display: block;
    background: url("../assets/images/kjb/common/promotions-btn-bg.png");
    background-size: cover;
    height: 50px;
    line-height: 50px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 5fr;
    &.buyCryptoBtn {
      padding: 0 5px;
      display: flex !important;
      svg {
        margin-right: 5px;
        path {
          fill: #ffa500;
        }
      }
    }
    svg {
      // margin-right: 5px;
      path {
        fill: #ffa500;
      }
    }
  }

  &.bannerBtn {
    border-radius: 10px;
    // border: 1px solid #fff;
    background: rgb(248, 85, 21);
    background: linear-gradient(
      90deg,
      rgba(248, 85, 21, 1) 25%,
      rgba(244, 16, 16, 1) 100%
    );
  }

  &.orengeRedBtn {
    background: rgb(248, 85, 21);
    background: linear-gradient(
      90deg,
      rgba(248, 85, 21, 1) 25%,
      rgba(244, 16, 16, 1) 100%
    );
    &:hover {
      background-color: #f1980c !important;
      background: linear-gradient(90deg, #f1980c 25%, #f1980c 100%);
    }
  }
  &.loadMoreBtn {
    padding: 0 50px;
    cursor: pointer;
  }
  &.redBtn {
    background: #e50d30;
  }
}
