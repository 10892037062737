.home_banner_v2 {
  padding: 25px 0;
  .create_account_area {
    text-align: center;
    .title_text {
      font-family: "Jost";
      font-size: 23px;
      font-weight: 500;
      font-style: normal;
      //   text-align: left;
      color: #ffffff;
    }
    .or_linke {
      width: 260px;
      text-align: center;
      border-bottom: 1px solid #2e445b;
      line-height: 0.1em;
      margin: 20px auto;
      span {
        background: #001a35;
        padding: 0 10px;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
      }
    }

    .openAccountBtn {
      width: 300px;
      height: 40px;
      line-height: 40px;
    }
  }
  .home_banner_area {
    // background: url("../../assets/images/v2/common/banner-img-1.webp") no-repeat;
    img {
      width: 125px;
    }
    @media (max-width: 768px) {
      // background: url("../../assets/images/v2/common/banner-img-1.webp") no-repeat center;
      text-align: center;
      img {
        margin: 15px auto;
      }
    }
  }
}

.type_banner_area {
  margin-bottom: 50px;
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    display: block;
  }

  .type_banner_item {
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
    .type_heading {
      margin-bottom: 10px;
      font-family: "Jost";
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      font-style: normal;
      text-align: left;
      color: #ffffff;
      svg {
        margin-right: 10px;
        path {
          fill: #485e6d;
        }
      }
    }
    img {
      width: 100%;
      background-color: #06274b;
    }
    .type_banner_content {
      background-color: hsl(211, 85%, 16%);
      padding: 15px;
      border-radius: 0 0 3px 3px;
      .type_banner_heading {
        font-family: "Jost";
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
      }
      .type_banner_details {
        font-family: "Jost";
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}
