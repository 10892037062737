footer {
  background-color: $footerBackground;
  .footer {
    // background: $text_color_v1;
    // background: #eeeeee;

    padding: 0px 0 20px;

    .footer_top {
      // border-top: 1px solid #3c7abf;
      padding: 50px 0;
      @media (max-width: 768px) {
        padding: 20px 0;
      }
      .logos_area {
        .site_logo {
          img {
            width: 180px;
          }
          @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 30px;
          }
        }
        .currency_icons {
          @media (max-width: 767px) {
            text-align: center;
          }
          margin: 35px 0 25px;
          ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            @media (max-width: 767px) {
              justify-content: center;
            }
          }
        }
        .payment_icon {
          @media (max-width: 767px) {
            text-align: center;
            margin: 0px 0 40px;
          }
        }
      }
      .links_section {
        margin-bottom: 30px;
        @media (max-width: 767px) {
          text-align: center;
        }
        .links_heading {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: #57575a;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        ul {
          margin: 0;
          padding: 0;
          &.social {
            display: flex;
            gap: 15px;
            margin-top: 10px;
          }
          li {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #88888c;
            a {
              text-decoration: none;
              color: #88888c;
              &.active {
                color: #88888c;
              }
              &:hover {
                text-decoration: underline;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .currency_brands {
      border-top: 1px solid rgba(85, 101, 126, 0.15);
      // padding: 15px 15px;
      // padding-top: 30px;
      @media (min-width: 768px) and (max-width: 1199px) {
        // padding: 30px 15px;
      }
      @media (max-width: 768px) {
        // padding: 10px 0px;
      }
      .slick-next,
      .slick-prev {
        display: none !important;
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(8, 1fr);
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        &.top_currency {
          grid-template-columns: repeat(8, 1fr);
          @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }

          &.footer_logos {
            grid-template-columns: repeat(7, 1fr);
          }
        }

        li {
          img {
            // width: 100%;
            height: 75px;
            // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            // filter: grayscale(100%);
            // opacity: 0.7;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            @media (max-width: 1199px) {
              width: 100%;
              height: auto !important;
            }

            &:hover {
              -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
              filter: grayscale(0%);
              opacity: 1;
            }
          }
          a.dotIgaming {
            svg {
              fill: white;
              width: 150px;
            }
            img {
              // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
              // filter: grayscale(100%);
              // opacity: 0.7;
            }
            .hoverImg {
              display: none;
            }
            &:hover {
              svg {
                fill: #f1980c;
                width: 150px;
              }
              img {
                -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                filter: grayscale(0%);
                opacity: 1;
              }
              .hoverImg {
                display: block;
              }
              .viewImg {
                display: none;
              }
            }
          }
          .master_visa_logo {
            // height: 40px;
          }
        }
        &.bottom_currency {
          margin: 15px auto;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          justify-items: center;
          margin-top: 30px;
          width: 50%;
          @media (max-width: 768px) {
            width: auto;
          }
          li {
            img {
              // width: 80px;
              height: 30px;
            }
          }
        }

        &.mobile_currency_brands {
          display: block;
          li {
            // height: 40px;
            padding: 0 15px;
            img {
              height: 40px !important;
              width: auto !important;
            }
          }
        }
      }
    }

    .gamming_brands {
      border-top: 1px solid #3c7abf;
      padding: 50px 100px;
      @media (min-width: 768px) and (max-width: 1199px) {
        padding: 30px 15px;
      }
      @media (max-width: 768px) {
        padding: 30px 0px;
      }

      .payOpIcon {
        width: 100%;
        color: #13447a;
        fill: #13447a;
      }
      .plus18Icon {
        width: 50px;
      }

      .dotiGamingLogo {
        width: 130px;
        fill: #13447a;
        &:hover {
          fill: #fff;
        }
      }
      ul {
        margin: 0px;
        padding: 0px;
        display: grid;
        grid-gap: 25px;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        justify-items: center;
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        &.bottomMenu {
          grid-template-columns: repeat(4, 1fr);
          padding: 0 100px;
          margin-top: 20px;
          @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 0px;
          }
        }

        li {
          img {
            height: 40px;
            // @media (max-width: 768px) {
            //   height: auto;
            //   width: 100%;
            // }
            @media (max-width: 1199px) {
              width: 100%;
              height: auto !important;
            }
          }
          &.dotIgamingLogo {
            &:hover {
              .hoverImg {
                display: block;
              }
              .solidImg {
                display: none;
              }
            }

            .solidImg {
              display: block;
            }
            .hoverImg {
              display: none;
            }
            // img{
            //   width: 150px;
            // }
          }
        }
        &.bottom_currency {
          margin: 15px auto;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          justify-items: center;
          margin-top: 30px;
          width: 50%;
          li {
            img {
              width: 80px;
            }
          }
        }
        &.gammingBrandSettings {
          display: block;
          li {
            // height: 40px;
            padding: 0 15px;
            img,
            svg {
              height: 40px !important;
              width: auto !important;
            }
          }
        }
      }
    }

    .footer_bottom {
      border-top: 1px solid rgba(85, 101, 126, 0.15);
      // margin-top: 30px;
      padding-top: 30px;
      .secure_logo {
        text-align: center;
        margin: 15px 0 30px;
      }
      .info_text {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #6e6e6e;
      }
      .partners_logos {
        margin-top: 70px;
        @media (max-width: 991px) {
          display: none;
        }

        ul {
          display: flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
          align-items: center;
          li {
            &.dotIgamingLogo {
              &:hover {
                .hoverImg {
                  display: block;
                }
                .solidImg {
                  display: none;
                }
              }

              .solidImg {
                display: block;
              }
              .hoverImg {
                display: none;
              }
              img {
                width: 150px;
              }
            }
          }
        }
      }
      .logo_and_social_links {
        text-align: center;
        .footerGeniLogo {
          width: 100px;
        }
        .copyright_text {
          font-family: $fontFamily;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          text-align: center;
          margin: 15px 0;

          color: #3e3e55;
          text-shadow: 1px 1px 1px #101010;
        }
      }
    }
  }
  .social_crypto {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(85, 101, 126, 0.15);
    padding: 15px 0;
    .social_link_area {
    }
    .crypto_area {
    }
  }

  .crypto_short_logos {
    padding: 0 15px;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
    li {
      img {
        width: 30px;
      }
    }
  }
}

.dotG {
  fill: red;
}

.sticky_footer_menu_area {
  z-index: 100;
  position: sticky;
  bottom: -1px;
  .sticky_search_area {
    // border: 1px solid $first_color;
    // background-color: $bg_color;
    height: 40px;
    padding: 5px 10px;
    display: none;
    transition: all 3s;
    input {
      border-radius: 8px;
      // background: $input_bg_color;
      color: $white;
      height: 30px;
      // transition: visibility 0s linear 700ms, opacity 700ms;

      // font-family: $font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      width: 100%;
      padding: 0 10px;
      border: none;
      &:focus {
        border: none;
        outline: none;
      }
    }
    &.showSearch {
      display: block;
    }
  }
  ul {
    border-top: 1px solid $gold-color;
    background-color: rgba(6, 6, 14, 0.9);
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    li {
      padding: 5px 0 15px 0;
      list-style: none;
      color: $white;
      // line-height: 40px;
      a {
        color: $white;
        .svg_icon {
          fill: $white;
        }
        .menu_text {
          font-size: 11px;
          // font-family: $font;
          line-height: 10px;
        }
        &:hover {
          color: $gold-color;
          text-decoration: none;
        }
      }
      &:hover {
        color: $gold-color;
      }
    }
  }
}

.licenseTextArea {
  padding: 15px;
  padding-top: 5px;
  font-size: 9px;
  color: $white;
  margin-bottom: 0px;
  color: #88888c;
}
.license_area {
  // padding-top: 30px;
  // padding-bottom: 10px;
  margin-top: -12px;
  .plus18IconArea {
  }

  padding: 0 15px;
  display: flex;
  grid-template-columns: 50px 50px 8fr 250px;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  .plus18Icon {
    width: 35px;
  }

  .responsible_gambling {
    svg {
      width: 105px;

      path {
        fill: #51525f;
      }
    }
  }

  .license_logo_area {
    // margin-bottom: 20px;
    gap: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    & > div {
      display: flex;
      justify-content: center;
    }
  }
  .license_logo {
    display: flex;
    justify-content: center;
    grid-column-gap: 20px;
    @media (max-width: 520px) {
      margin-top: 20px;
    }
    .curacao_license_area {
      width: 90px;
    }
  }

  .license_text {
    color: #6e6e6e;
    font-size: 12px;
    text-align: center;
    text-shadow: 1px 1px 1px #101010;
    @media (max-width: 767px) {
      padding: 20px 0 0 0px;
    }
  }
  .footer_copyright_text {
    margin-top: 15px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
}

.brands {
  // padding-top: 35px;
  ul {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 16px 12px;
    margin-bottom: 0;
    li {
      margin: 10px;
      list-style: none;
      a {
        opacity: 1;

        &:hover {
          opacity: 1;
        }
      }
      .brand_img {
        height: 30px;
        width: auto;
      }
      &.dotIgamingLogo {
        &:hover {
          .hoverImg {
            display: block;
          }
          .solidImg {
            display: none;
          }
        }

        .solidImg {
          display: block;
        }
        .hoverImg {
          display: none;
        }
        // img{
        //   width: 150px;
        // }
      }
    }
  }
}

.footer_copyright_text {
  margin-top: 15px;
  color: #6e6e6e;
  font-size: 12px;
  text-align: center;
}

.licenseSeal {
  z-index: 1 !important;
}

.footerAccordionMenu {
  .accordion-item {
    background-color: unset !important;
    border: none;
    .accordion-header {
      .accordion-button {
        background-color: unset !important;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #57575a;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-align: center;
        box-shadow: none;
        padding: 0;
        display: grid;
        grid-template-columns: auto 20px;

        &::after {
          content: url("../assets/images/kjb/icons/angle-down.svg");
        }
      }
    }
    .accordion-body {
      padding: 0px;
      text-align: center;
      margin-bottom: 20px;
      ul {
        margin: 0;
        padding: 0;
        &.social {
          display: flex;
          gap: 15px;
          margin-top: 10px;
        }
        li {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #88888c;
          a {
            text-decoration: none;
            color: #88888c;
            &.active {
              color: #88888c;
            }
            &:hover {
              text-decoration: underline;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
