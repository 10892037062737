.modal {
  .modal-dialog {
    .modal-content {
      background: $modalBg;
    }
  }
}

.greyStyleModal {
  .modal-content {
    .modal-header {
      padding: 30px;
      .modal-title {
        color: #fff;
      }
      .btn-close {
        background-color: #fff;

        &:hover {
          background-color: #f1980c;
          opacity: 1;
        }
      }
    }
    .modal-body {
      color: #fff;
      padding: 30px;
    }
  }
  &.affiliateTermsModal {
    .modal-content {
      // padding: 15px;
      height: 90vh;
      // overflow-y: auto;
      overflow: hidden;
      .affiliate_terms_area {
        height: 70vh;
        overflow-y: auto;

        a {
          color: #f1980c !important;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .paragraph {
          a {
            color: #f1980c !important;
            text-decoration: none;
            span {
              color: #f1980c !important;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.freespinmodal {
  color: white;
}
