@font-face {
  font-family: "Impact";
  src: url("../assets/fonts/Impact.eot");
  src: url("../assets/fonts/Impact.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Impact.woff2") format("woff2"),
    url("../assets/fonts/Impact.woff") format("woff"),
    url("../assets/fonts/Impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Impact";
  src: url("../assets/fonts/Impact_1.eot");
  src: url("../assets/fonts/Impact_1.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Impact_1.woff2") format("woff2"),
    url("../assets/fonts/Impact_1.woff") format("woff"),
    url("../assets/fonts/Impact_1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Impacted";
  src: url("../assets/fonts/Impacted.eot");
  src: url("../assets/fonts/Impacted.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Impacted.woff2") format("woff2"),
    url("../assets/fonts/Impacted.woff") format("woff"),
    url("../assets/fonts/Impacted.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
