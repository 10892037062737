$purple: #863ab7;
$greyish: #304a71;

.register {
  background: $header_bg;
  padding: 40px 40px 20px 40px;
  border-top: 1px solid $dark_blue_bg;
  border-bottom: 1px solid $dark_blue_bg;
  position: relative;
  // width: 100%;
  color: $white;
  @media (max-width: 767px) {
    padding: 15px !important;
  }

  .headline {
    color: white;
    line-height: 1;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 15px;
  }

  .input_item {
    margin: 10px 0;
    position: relative;
    .error_msg {
      position: absolute;
      bottom: -21px;
      font-size: 13px;
    }

    .form-check {
      label {
        font-size: 14px;
      }
    }
  }

  .name_value {
    color: $white;
    background: $background_blue_dark;
    border: none;
    box-shadow: none;
    &:focus {
      color: $white;
      background: $background_blue_dark;
      box-shadow: none;
    }
  }

  .error_msg {
    color: red;
  }

  a.common_link {
    position: relative;
    text-decoration: none;
    color: $white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      to right,
      $custom-hover-color,
      $custom-hover-color 50%,
      #fff 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s ease-in-out;
    &:before {
      display: block;
      content: "";
      width: 0;
      height: 3px;
      bottom: 5px;
      left: 0;
      bottom: -3px;
      z-index: 0;
      position: absolute;
      background: $custom-hover-color;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
    &.active {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
  }
}

$font: "Cairo", sans-serif;
$modal_bg_color: #2a2a44;
$bg_color: #06060e;
$main_color: #863ab7;
$main_color_deep: #863ab7;
$input_bg_color: #19192c;
$reg_base_color: #863ab7;
$reg_base_deep_color: #863ab7;
$input_normal_border_color: rgba(0, 0, 0, 0.38);
$input_normal_focused_border_color: #add8e6;

$placeholder_text_color: rgba(0, 0, 0, 0.54);
$input_error_border_color: #e60000;
$input_error_focused_border_color: #e60000;
$input_accept_border_color: #1daa63;
$input_accept_focused_border_color: #1daa63;

.border_radius_20 {
  .modal-content {
    border-radius: 30px !important;
  }
}
.login_modal {
  overflow: hidden;
  // background: #0c164a;
  border-radius: 10px;
  //   position: relative;
  // border-radius: 4px;
  padding: 0px !important;
  // padding: 30px 30px !important;
  //   width: 400px !important;
  // margin: 0 auto;
  @media (max-width: 575px) {
    // width: 290px !important;
    padding: 10px !important;
  }
  &.reg_section {
    // width: 600px !important;
    @media (max-width: 575px) {
      //   width: 290px !important;
      padding: 10px !important;
    }
  }

  .modal_headline {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
    &.sub_headline {
      font-size: 17px;
      line-height: 25px;
    }
    .backStep {
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .login_mode {
    background: $bg_color;
    border-radius: 25px;
    width: fit-content;
    margin: 0 auto;

    .mode_btn {
      margin: 5px;
      display: inline-block;
      height: 36px;
      text-decoration: none;
      width: 100px;
      border-radius: 25px;
      line-height: 36px;
      font-family: $font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      text-transform: capitalize;
      color: $white;

      &:hover {
        background: $main_color;
      }
      &.active {
        background: $main_color;
      }
      @media (max-width: 767px) {
        height: 30px;
        line-height: 30px;
        width: 90px;
      }
    }
  }
  .form_area {
    margin: 20px 0 10px;
    .input_item {
      position: relative;
      margin-bottom: 20px;
      @media (max-width: 575px) {
        margin-bottom: 10px;
      }
      .name {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: $white;
        margin-bottom: 8px;

        .requried_icon {
          color: $theme_color_v1;
          padding-left: 5px;
        }
        .forgot_pass_link {
          float: right;
          text-decoration: underline;
          cursor: pointer;
          color: $white;
        }
      }
      .name_valueB {
        border-radius: 10px;
        background: #042d69;
        color: rgba(255, 255, 255, 0.59);
        height: 40px;
        font-family: $font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        border: none;
        &:focus {
          border: none;
          outline: none;
        }

        @media (max-width: 767px) {
          height: 38px;
          line-height: 38px;
        }
      }
      .show_hide_pass {
        position: absolute;
        right: 10px;
        top: 35px;
        color: rgba(255, 255, 255, 0.69);
      }
      .errorMsg {
        color: red;
        font-size: 12px;
      }

      .three_fields {
        display: flex;
        gap: 20px;
      }
    }
    .login_button {
      margin: 30px 0px;
      .login_btn {
        width: 100%;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #08165d;
        opacity: 1;
        border-radius: 30px;
        background: $theme_color_v1;

        @media (max-width: 767px) {
          height: 34px;
          line-height: 17px;
        }
      }
    }
  }

  .paragraph {
    a {
      color: $reg_base_color;
    }
  }

  .privacy_text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: $white;

    a {
      color: $theme_color_v1;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .routing_text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin-top: 15px;

    color: $white;
    a {
      text-decoration: none;
      color: #fff778;
    }
  }
  .bordered_text {
    padding: 15px;
    background: #19192c;
    border-radius: 5px;
    font-size: 13px;
  }

  &.forgot_pass {
    width: 100%;
  }

  .modalBg {
    // height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.login {
      background-image: url("../assets/images/v2/common/login-modal.jpg");
    }
    &.register {
      background-image: url("../assets/images/v2/common/reg-modal.jpg");
    }
  }

  &.forgot_pass {
    padding: 30px !important;
  }

  &.message_modal {
    .message_content {
      padding: 30px;
      color: $white;
    }
  }
}

.reg_check_area {
  .check_area {
    width: 100%;
    cursor: pointer;
    .check_check_and_text_area {
      // padding: 0 15px;
      margin-top: 10px;
    }
    .check_box {
      vertical-align: sub;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      border: 2px solid $white;
      border-radius: 5px;

      text-align: center;
      .check_checked_box {
        width: 10px;
        height: 10px;
        background: $theme_color_v1;
        margin: 3px auto 0 auto;
        display: none;
      }
    }
    .check_text {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* or 200% */
      color: $white;
      display: inline-block;
      width: calc(100% - 35px);
      vertical-align: top;

      a {
        color: $theme_color_v1;
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked + label {
      .check_box {
        border: 2px solid $theme_color_v1;
      }
      .check_checked_box {
        display: block;
      }
    }
  }
  .error_text {
    /*display: none;*/
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    word-break: break-word;
    clear: both;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: $input_error_border_color;
    margin: 4px 12px 8px;
  }

  &.error_input {
    .reg_input {
      outline: none;
      border-color: $input_error_border_color;
      -webkit-box-shadow: 0 0 5px $input_error_border_color;
      box-shadow: 0 0 5px $input_error_border_color;
    }
    .input_info {
      display: none;
    }
    .error_text {
      display: block;
    }
  }
}
.login_reg_area {
  padding: 30px 15px;

  .social_login_registration {
    text-align: center;
    .social_login_reg_text {
      color: $white;
      font-size: 14px;
      margin-bottom: 5px;
    }
    ul {
      padding: 0px;
      li {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
          0 1px 2px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        background-color: $purple;
        display: inline-block;
        margin: 0 2px;
        text-align: center;
        a {
          // padding: 0 15px;
          color: $white;
          line-height: 35px;
          width: 80px;
          display: block;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}

.errorMsg {
  font-size: 12px;
  color: red;
}
