.dropdown {
  button {
    border: none;
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }
  }
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-menu {
  background: $leftMenuBackground !important;
  border: 1px solid #252637;
  a {
    color: $white;
    &:hover {
      background: $hoverBg;
      color: $white;
    }
  }
}
