.security_overlay {
  position: fixed;
  z-index: 1000;
  background: black;
  width: 100vw;
  height: 100vh;
  .security_popup_logo {
    position: absolute;
    top: calc(42% - 100px);
    width: 100%;
    text-align: center;
    video,
    img {
      max-width: 250px;
      width: 90%;
    }
  }
  .security_popup_trigger {
    position: absolute;
    right: 20px;
    top: 20px;
    width: auto;
  }
}

.security_popup {
  padding: 20px !important;
  .modal_headline {
    font-size: 22px;
  }
}
