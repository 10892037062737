//common color config
$mainBackground: #101016;
$mainContentBackground: #101016;
$dashboardContentBackground: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;

$headerMenuBackground: #161721;
$footerBackground: #161721;
$leftMenuBackground: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;
$gamePageBackground: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;
// $leftSideBackground: #032040;
$modalBg: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;
$dropdownBg: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;

$inputBg: linear-gradient(#000000, #000000) padding-box,
  linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000) border-box;

$hoverBg: linear-gradient(180deg, #3d4055 0%, rgba(51, 54, 71, 0) 127.81%);
$gameHeadingBg: linear-gradient(
  180deg,
  #3d4055 0%,
  rgba(51, 54, 71, 0) 127.81%
);

$buttonColorOne: #e9a147;
$yellowColor: #ebcc0f;
$yellow: #ebcc0f;
$greenColor: #1bb83d;
$lightGray: #9db8e1;

$blueBtnBg: linear-gradient(180deg, #1099a9 11.77%, #172b54 185.64%);
$blueBtnBgRev: linear-gradient(180deg, #172b54 11.77%, #1099a9 185.64%);
$dashboardBg: radial-gradient(
    391.34% 116.55% at 86% -55.09%,
    #2f2f43 0%,
    rgba(47, 47, 67, 0) 100%
  ),
  #161721;

$modalBackground: #06264c;

$white: #fff;

// $inputBg: #001a35;
$inputBorderColor: #252637;

//promotion color config
$promotionHeadingBackground: "#192936";

// @import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// $fontFamily: "Jost";

//other variable needed
$text_color_v1_dark: #eeeeee;
$light_block_bg: linear-gradient(180deg, #efefef 0%, #dfdfdf 100%);
$dark_block_bg: linear-gradient(180deg, #282828 0%, #141414 100%);
$heading_color_v1: #1f2933;
$text_color_v1: #666666;
$theme_color_deep: #f9a915;
$theme_color_light: #00bcf2;
$common_text_color: #7d8386;
$dark_blue_bg: #162329;
$deep_gold_color: #c8860b;
$black: black;
$blue: rgba(0, 188, 242, 1);
$gold_color: #f9a915;
$light_blue: rgb(77, 166, 234);
$header_bg1: #385158;
$header_bg2: #1d3b6a;

$header_bg: linear-gradient(to right, $header_bg1, $header_bg2);
$background_blue_dark: #202020;
$custom-hover-color: #4de8ff;
$black_bg: #191919;
$hoveColor: #374f56;
$grass_green: #009a17;

//Font Style
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$fontFamily: "Poppins";
