.products {
  .products_headine {
    color: $theme_color_deep;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dashed {
      margin-left: 10px;
      width: 90%;
      height: 1px;
      background: $theme_color_deep;
    }
  }

  .product_details_headine {
    color: $white;
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dashed {
      margin-left: 10px;
      width: 70%;
      height: 1px;
      background: $theme_color_deep;
    }
  }
  .product_details {
    color: $white;
    img {
      width: 100%;
    }
    a {
      color: $theme_color_deep;
    }
    ul {
      margin-top: 15px;
      li {
        list-style: none;
      }
    }
    p {
      margin-bottom: 0px;
    }
  }
  .product_lists {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
    grid-gap: 15px;
    .single_product {
      position: relative;
      border: 1px solid #e9a147;
      // padding-bottom: 15px;
      border-radius: 5px;

      .product_img {
        img {
          width: 100%;
          border-radius: 5px;
        }
      }
      .product_details {
        position: absolute;
        min-height: 160px;
        top: 0px;
        left: 0px;
        width: 100%;
        padding: 10px;
        text-align: center;
        .product_heading {
          font-size: 20px;
          font-weight: 600;
          text-shadow: 1px 1px 1px #000;
        }
        .product_part_info {
          font-size: 16px;
          font-weight: 500;
          text-shadow: 1px 1px 1px #000;
        }
      }
      .buy_products {
        position: absolute;
        bottom: 10px;
        text-transform: capitalize;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .product_title {
        margin: 15px 0;
        min-height: 56px;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        color: $white;
        display: -webkit-box;
        vertical-align: middle;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &:hover {
          color: $theme_color_deep;
        }
      }

      .load_more_btn {
        margin-top: 5px !important;
      }
    }
  }
  .others_products {
    .others_products_headline {
      border-bottom: 2px solid $theme_color_deep;
      .common_btn {
        border-radius: 0 !important;
        margin: 0px;
      }
    }
    .other_single_product {
      display: grid;
      grid-template-columns: 30% 70%;
      grid-gap: 15px;
      margin: 20px 0;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
      padding-bottom: 15px;
      &:nth-last-child(1) {
        border-bottom: none !important;
      }
      .product_img {
        img {
          width: 100%;
          border-radius: 5px;
          min-height: 80px;
          // border: 1px solid #3cba18;
        }
      }
    }
  }
}
