body {
  background-color: $mainBackground !important;
}
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.main_content_area {
  // background-color: $mainContentBackground;
  // padding: 1rem 2rem;
  margin-left: 15px;
  @media (max-width: 768px) {
    padding: 0px;
    margin: 0;
  }
}

.social_link_area {
  text-align: center;
  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    li {
      background-color: #42566a;
      width: 70px;
      height: 40px;
      line-height: 40px;
      margin: 0 2px;
      border-radius: 3px;
      cursor: pointer;
      i {
        color: #fff;
      }
      &:hover {
        background-color: $yellow;
        i {
          color: #f1980c;
        }
      }
    }
  }
}
.main_body_v1 {
  display: grid;
  grid-template-columns: 260px auto;
  background-color: $mainBackground;
  &.slideLeft {
    grid-template-columns: 60px auto;
    @media (min-width: 768px) and (max-width: 1199px) {
      grid-template-columns: 75px auto;
    }
    .leftside_v2 {
      width: 70px;
      @media (min-width: 768px) and (max-width: 991px) {
        width: 0px;
      }
    }
  }

  //Main body animetion
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  @media (max-width: 991px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: 215px auto;
  }

  .left_bar_v1 {
    padding-left: 15px;
    // @media (max-width: 991px) {
    //   display: none !important;
    // }

    &.mobileBar {
      position: fixed;
      // left: -260px;
      top: 80px;
      @media (max-width: 767px) {
        top: 70px;
      }

      z-index: 111;
      transform: translateX(-200px);

      &.showMibileBar {
        display: block !important;
        transform: translateX(0px);
        // left: 0px;
      }
    }
  }
  .right_bar_v1 {
    overflow: hidden;
    // &.gamePage {
    //   overflow: visible;
    // }
  }
}

.slideLeft {
  .left_bar_v1 {
    padding-left: 0px;

    .leftside_v2 {
      padding: 5px;
      z-index: 100;
      @media (min-width: 768px) and (max-width: 991px) {
        padding: 0px;
      }
      .casino_type {
        display: block;
        // transition-delay: 1s;
        a {
          width: auto;
          display: block;
          .text {
            display: none;
          }
          &:hover,
          &.active {
            background: none;
            border: none;
          }
          .icon {
            img {
              width: 20px;
            }
          }
        }
      }

      .searchbar_searchbox {
        input::placeholder {
          color: transparent;
        }
        .search-icon {
          cursor: pointer;
        }
      }
      .menus {
        padding: 0;
        li {
          text-align: center;
          .text {
            display: none;
          }
          .icon {
            margin-right: 0px;
          }
        }
      }
      .theme_mode {
        li {
        }
      }
    }
  }

  .languageSwitcherAccordion {
    display: none;
  }
}

.slide_direction {
  margin-top: 15px;
  text-align: center;
  button {
    margin: 0 5px;
    background-color: $buttonColorOne;
    border: none;
    &:hover {
      background-color: $yellowColor;
    }
  }
}

.backDrop {
  width: 100vw;
  height: 100vh;
  background: #0000005c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.modal {
  .modal-90w {
    width: 90%;
    max-width: none !important;
  }
  .modal-80w {
    width: 80%;
    max-width: none !important;
  }
  .modal-70w {
    width: 70%;
    max-width: none !important;
  }
}

.input_item {
  position: relative;
  margin-bottom: 15px;
  .name {
    color: $text_color_v1_dark;
    margin-bottom: 5px;
    .forgot_pass_link {
      color: $white;
    }
    .requried_icon {
      color: red;
    }
  }

  .name_value {
    background: $inputBg;
    border: none;
    color: $text_color_v1_dark;
    transition-duration: 300ms;
    border: 1px solid transparent;
    border-bottom: 1px solid $inputBorderColor;
    box-shadow: 0px 1px 1px #252637;
    outline: 0;
    border-radius: 5px;
    &::placeholder {
      color: #767575;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #767575;
    }
    &[readonly] {
      opacity: 0.8 !important;
      background: $inputBg !important;
      border-bottom: 1px solid $inputBorderColor !important;
    }
    &:hover {
      background: $inputBg !important;
      // border: none !important;
      color: $text_color_v1_dark !important;
      border-bottom: 1px solid $inputBorderColor !important;
      outline: none !important;
      box-shadow: 0px 1px 1px #65b6c0 !important;
      // box-shadow: 0px 1px 1px rgba(97, 105, 114, 1) !important;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background: $inputBg !important;
      // border: none !important;
      color: $text_color_v1_dark !important;
      border-bottom: 1px solid $inputBorderColor !important;
      box-shadow: 0px 1px 1px #65b6c0 !important;
    }
    &.phoneNumber {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &.input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    option {
      background: #000 !important;
    }

    .input-group-text {
      background-color: orange;
      border: none;
    }
  }

  .in_input_icon {
    position: absolute;
    right: 10px;
    top: 40px;
    i {
      color: $white;
      &:hover {
        color: $inputBorderColor;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  // margin-top: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #010101;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333232;
}
.widget-visible {
  // display: none;
  iframe {
    z-index: 0 !important;
    // display: none !important;
  }
}

.swal-modal {
  background: $leftMenuBackground !important;
  border: 1px solid $inputBorderColor;

  &.dark {
    background: $leftMenuBackground !important;

    .swal-title {
      color: white;
    }
    .swal-text {
      color: $text_color_v1_dark;
    }
    .swal-icon {
      background-color: $dashboardBg !important;
      &::before,
      &::after {
        background-color: $dashboardBg !important;
        background: none !important;
      }
      &::before {
        top: -8px;
      }
      .swal-icon--success__hide-corners {
        background-color: $dashboardBg !important;
        background: none !important;
      }
    }
  }

  .swal-title {
    color: $heading_color_v1;
  }
  .swal-text {
    color: $text_color_v1;
  }
  .swal-icon {
    background-color: $light_block_bg !important;
    &::before,
    &::after {
      background-color: $light_block_bg !important;
    }
    &::before {
      top: -8px;
    }
    // .swal-icon--success__hide-corners {
    //   background-color: $light_block_bg !important;
    // }
  }
  .swal-button-container {
    .swal-button {
      color: white;
      background: $buttonColorOne;
      outline: none !important;
      opacity: 1;
      box-shadow: none !important;
      border-radius: 5px;
      &:hover {
        color: white;
        background: $yellowColor;
        outline: none !important;
        opacity: 1;
        box-shadow: none !important;
      }
    }
  }
}

.buy-crypto-block {
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: radial-gradient(
  //     16.4% 98.04% at 46.91% 83.33%,
  //     rgba(34, 131, 246, 0.2) 0,
  //     rgba(34, 131, 246, 0) 100%
  //   ),
  //   radial-gradient(
  //     30.48% 106.37% at 34.46% 83.82%,
  //     #ebce0f4a 0,
  //     rgba(237, 29, 73, 0) 100%
  //   ),
  //   #161f2c;

  @media (max-width: 768px) {
    display: block;
    padding: 15px;
  }

  .buy-crypto-block__text {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 135%;
    // max-width: 215px;
    color: $white;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 15px;
      display: block;
      width: 100%;
    }
  }

  .buy-crypto-block__methods {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      gap: 15px;
    }
    .buy-crypto-block__method {
      img {
        height: 35px;
      }
    }
  }
  .commonBtn {
    @media (max-width: 768px) {
      margin: 0 auto;
      display: block;
    }
  }
  &.leftSideBlock {
    margin-top: 10px;
    display: block;
    padding: 0px;

    .buy-crypto-block__methods {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @media (max-width: 768px) {
        // margin-bottom: 15px;
        // gap: 15px;
      }
      .buy-crypto-block__method {
        img {
          height: 20px;
        }
      }
    }
    .commonBtn {
      display: block;

      height: auto;
      // line-height: 40px;
      margin: 0 0px;
      margin-bottom: 15px;
    }
  }
}

.registerBtn {
  position: relative;
  .giftIcon {
    width: 30px;
    position: absolute;
    right: -15px;
    top: 0px;
  }
}

.form-control:disabled {
  // background: #16202b !important;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000) border-box !important;
}

iframe {
  z-index: -1 !important;
}

.slick-arrow {
  &::before {
    // background-color: #f41010;
  }
}

.jactpor_ticker_area {
  h2 {
    color: $white;
    text-align: center;
  }
  section {
    & > span {
      margin: 0 2px;
      border-radius: 5px;
      // padding: 5px;
      color: #202020 !important;
      background-color: #000 !important;
      font-size: 15px !important;
      // padding-top: 0;
      // display: flex !important;
      // justify-content: center !important;
      // align-items: center !important;
      &[style] {
        box-shadow: 1px 1px 1px #000;
      }
    }
    span {
      font-size: 25px !important;
    }
  }
  &.gameTicker {
    section {
      & > span {
        margin: 0 2px;
        border-radius: 3px;
        // padding: 5px;
        color: #202020 !important;
        font-size: 12px !important;
        padding-top: 0;
        background-color: #000 !important;
        // -webkit-padding-before: 3px;
        &[style] {
          box-shadow: 1px 1px 1px #000;
        }
      }
      span {
        font-size: 12px !important;
      }
    }
  }
}

.searchbar_searchbox {
  // margin: 15px 0;
  width: 100%;
  position: relative;
  .search_container {
    position: absolute;
    top: 10px;
  }
  .search_suggestions {
    display: block !important;
    border-radius: 5px;
    position: absolute;
    background-color: rgba(6, 6, 14, 0.9);
    top: 41px;
    max-width: 400px;
    // -webkit-box-orient: vertical;
    // -webkit-box-direction: normal;
    // -ms-flex-direction: column;
    // flex-direction: column;
    top: 51px;
    -webkit-box-shadow: 0 5px 10px #000;
    box-shadow: 0 5px 10px #000;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 201;
    min-height: 250px;
    border: 1px solid #f9a915;
    li {
      white-space: nowrap;
      display: flex !important;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style: none;
      cursor: pointer;
      margin: 0 0 0 0 !important;
      padding: 5px !important;
      img {
        width: 40px;
        margin-right: 10px;
        border-radius: 5px;
      }

      &:hover {
        background: #f9a9157a;
      }
    }
    &::before {
      // content: url("../assets/images/common/angle-up-solid.svg");
      // width: 10px;
      // height: 10px;
      // position: absolute;
      // top: -10px;
      // left: 10px;
    }
  }
  input {
    width: 100%;
    height: 41px;

    border-color: #1099a9;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;

    background: #06274b;
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  .caretUp {
    position: absolute;
    left: 5px;
    top: 29px;
    z-index: 202;
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.paragraph {
  a {
    color: #e9a147 !important;
    span {
      color: #e9a147 !important;
    }
  }
}

.gameCategoryDropdown {
  .providerLists {
    height: 280px !important;
  }
  .dropdown-item {
    display: grid;
    grid-template-columns: 30px auto;
  }
}
