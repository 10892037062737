.support_panel {
  width: 435px;
  position: fixed;
  top: 0;
  right: -435px;
  height: 100vh;
  z-index: 5000;
  padding: 20px;
  transition: all 0.3s;
  overflow-y: auto;
  background: #f0f0f0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  // border-left: 2px solid $site_bg;
  @media (max-width: 767px) {
    max-width: 100vw;
  }
  &.open {
    right: 0;
  }
  .panel_header {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    margin: 30px 0;

    /* UI/BG Dark */

    color: $heading_color_v1;
  }
  .close_icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    // background: #454568;
    color: $black;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: 20px;
    top: 40px;
    cursor: pointer;
  }
  .supportbar_tabs {
    margin-right: 20px;
    .nav-item {
      .nav-link {
        border-radius: 20px 20px 0 0;
        background: linear-gradient(
          180deg,
          rgba(212, 212, 212, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        // background: $light_block_bg;
        text-decoration: none;
        color: $text_color_v1;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        margin-right: 10px;
        padding: 10px 20px 10px 20px;

        @media (max-width: 575px) {
          padding: 10px !important;
          font-size: 14px !important;
        }

        &.active {
          background: linear-gradient(180deg, #bdbdbd 0%, #efefef 100%);
        }
      }
    }
  }
  .tab-content {
    border: 1px solid #dddcdc;
    border-radius: 0px 20px 20px 20px;
    padding: 20px;
    border-top: none;

    @media (max-width: 767px) {
      padding: 20px 0;
    }

    .accordion {
      // border: 1px solid $theme_color_light;
      border: none !important;
      .accordion-item {
        border: none !important;
        border-bottom: 1px solid #b3b3b3 !important;
        &:nth-last-child(1) {
          border-bottom: none !important;
        }
        .accordion-header {
          .accordion-button {
            font-weight: bold;
            font-size: 16px;
            margin: 0;
            box-shadow: none;
            position: relative;
            padding: 10px 15px;
            line-height: 30px;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            color: $heading_color_v1;
            background-color: none !important;

            &:not(.collapsed) {
              // background: rgba($theme_color_light, 0.3);
              color: $heading_color_v1;
              border: none !important;
              background-color: #dcdcdc;
              &::before {
                background-image: url("../assets/images/v1/icons/arrow-down.svg");
              }
            }
            &::after {
              display: none;
            }
            &::before {
              height: 13px !important;
              width: 20px !important;
              content: "";
              background-image: url("../assets/images/v1/icons/arrow-right.svg");
              background-repeat: no-repeat;
            }
            &:hover {
              color: $blue !important;
            }
          }
        }

        .accordion-body {
          padding: 10px;
          color: $text_color_v1;
          .form-control {
            margin-bottom: 15px;
            border: none;
            outline: none;
            input {
              outline: none;
            }
          }
        }
      }
    }

    .email_us {
      padding: 15px 0;
      .contact_us_area {
        margin-top: 15px;
        .reg_field {
          margin-bottom: 15px;
          input,
          textarea {
            box-shadow: none;
            &::placeholder {
              color: #868585;
              opacity: 1; /* Firefox */
            }

            &::-ms-input-placeholder {
              /* Edge 12 -18 */
              color: #868585;
            }
            &:focus {
              border: 1px solid $theme_color_light;
            }
          }
        }
      }
    }

    .support_chat {
      width: 100%;
      min-height: 500px;
      // height: calc(100% - 100px);
    }
  }

  .tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    a {
      display: block;
      text-align: center;
      border-radius: 5px 5px 0 0;
      line-height: 35px;
      width: 100%;
      text-decoration: none;
      color: $white;
      background-color: $black_bg;
      &.active {
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from($theme_color_deep),
          to($theme_color_light)
        );
        background: linear-gradient(
          to bottom,
          $theme_color_deep 0%,
          $theme_color_light 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$theme_color_deep', endColorstr='$theme_color_light',GradientType=0 );
      }
    }
  }

  .support_sidebar_content {
    width: 100%;
    height: calc(100% - 100px);
    padding-top: 15px;
  }
}
