// Used by races page and homepage, so its out of races_page class
$theme_color_v1: #e9a147;

.race_list_v1 {
  .single_race_item {
    .race_date {
      text-align: right;
      font-family: "Myriad Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $white;
      margin: 30px 0 15px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .single_race_body {
      position: relative;
      img {
        width: 100%;
        @media (max-width: 767px) {
          min-height: 250px;
        }
      }
      .info_btn {
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px;
        @media (max-width: 992px) {
          top: 15px;
          right: 15px;
        }
        &:hover {
          svg {
            path {
              fill: #264ef8 !important;
            }
          }
        }
      }

      .text_section_area {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .title {
          font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 47px;
          line-height: 56px;
          text-align: center;
          color: $white;

          @media (max-width: 767px) {
            font-size: 25px;
            line-height: 30px;
          }
          @media (min-width: 768px) and (max-width: 992px) {
            font-size: 30px;
            line-height: 50px;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 40px;
            line-height: 60px;
          }

          &.small {
            font-family: "Myriad Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 15px;
            }
            @media (min-width: 768px) and (max-width: 992px) {
              font-size: 15px;
              line-height: 18px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 17px;
              line-height: 20px;
            }
          }
          &.bold {
            font-weight: 700;
          }
          &.colored {
            font-family: "Myriad Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 65px;
            line-height: 78px;
            text-align: center;
            text-transform: uppercase;
            color: $theme_color_v1;
            @media (max-width: 767px) {
              font-size: 35px;
              line-height: 40px;
            }
            @media (min-width: 768px) and (max-width: 992px) {
              font-size: 40px;
              line-height: 45px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 45px;
              line-height: 50px;
            }
          }
          &.uppercase {
            text-transform: uppercase;
          }
        }
        .race_btn {
          margin-top: 30px;
          @media (max-width: 767px) {
            margin-top: 15px;
          }
          a {
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .more_btn {
    margin-top: 50px;
  }
}

.races_page {
  // background: linear-gradient(
  //   145.83deg,
  //   #08155a 23.67%,
  //   #07155e 25.33%,
  //   #0c1645 60.02%,
  //   #091653 75.92%,
  //   #041569 85.03%,
  //   #071f94 103.27%
  // );

  // background: url("../assets/images/banners/-races.png") no-repeat top
  //   center;
  // width: 100%;
  // // padding: 6.25em 0px;
  // background-size: contain;

  .winner_toggle {
    box-shadow: rgb(86, 91, 119) 0px 0px 0px 1px inset;
    border-radius: 3px;
    width: fit-content;
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;

    .toggle_btn {
      font-size: 1em;
      text-align: center;
      line-height: 1.25;
      text-decoration: none;
      opacity: 0.6;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 3px;
      min-width: 85px;
      padding: 0.625em 1.125em;
      position: relative;
      white-space: nowrap;
      color: $white;

      &:hover {
        opacity: 1;
      }
      &.active {
        background: $dark_blue_bg;
        opacity: 1;
      }
    }
  }

  .race_list {
    li {
      list-style: none;

      .race_dot {
        border: 1px solid rgb(86, 91, 119);
        background: rgb(10, 16, 43);
        vertical-align: middle;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin: 0 auto;
      }

      .race_border {
        width: 1px;
        height: 30px;
        background: rgb(86, 91, 119);
        margin: 0 auto;
      }

      .race_content {
        width: 100%;
        max-width: 860px;
        background: rgb(18, 24, 51);
        background-image: url("../assets/images/common/modal_bg.png");
        min-height: 360px;
        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: 3px;
        margin: auto auto 2.5em;

        @media (max-width: 767px) {
          display: block;
          margin: auto auto 0;
        }

        .more_btn {
          .details_btn {
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 10px;
            line-height: 1;
            box-shadow: none;
            min-width: 0px;
            z-index: 2;
            width: 35px;
            height: 35px;
            border: transparent;
            background: rgb(31, 37, 65);
            border-radius: 50%;

            svg {
              fill: rgb(86, 91, 119);
            }

            &:hover {
              background: rgb(10, 16, 43);
              border: transparent;
            }
          }
        }

        .race_banner {
          clip-path: ellipse(55% 120% at 44% 50%);
          flex: 0 1 360px;
          display: grid;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          p {
            position: absolute;
            bottom: 10px;
            color: white;
            font-size: 14px;
            text-align: center;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
          }

          @media (max-width: 767px) {
            clip-path: unset;
          }

          @media (min-width: 768px) and (max-width: 991px) {
            flex: 0 1 200px;
          }
        }

        .race_details {
          padding: 15px;
          z-index: 1;
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          flex: 1 1 0px;
          .title {
            color: $white;
            text-align: center;
            font-size: 20px;
          }

          .time {
            color: $white;
            font-size: 3.1875em;
            font-weight: 600;
            margin-bottom: 20px;

            @media (max-width: 575px) {
              font-size: 2.1875em;
            }

            @media (min-width: 768px) and (max-width: 1199px) {
              font-size: 2.1875em;
            }
          }

          .bonuses {
            display: flex;
            justify-content: space-around;

            .single_item {
              .bonus_value {
                font-size: 1.42857em;
                font-weight: bold;
                margin-bottom: 0px;
                color: $white;
                &.min_bet {
                  margin-bottom: 5px;
                  line-height: 19px;
                }
                @media (max-width: 575px) {
                  font-size: 1em;
                }
              }
            }
          }

          .view_leadboard {
            text-align: center;

            .leardboard_btn {
              margin: 30px auto 0;
              outline: 0px;
              font-size: 12px;
              line-height: 1.25;
              border: 1px solid $light_blue;
              box-shadow: none;
              border-radius: 25px;
              min-width: 85px;
              padding: 0.625em 1.125em;
              color: $light_blue;
              background: none;
              text-transform: uppercase;

              &:hover {
                background-color: $light_blue;
                border-color: $light_blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .promotions_banner_v1 {
    position: relative;
    .races_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      text-align: center;
      .banner_heading {
        font-family: "Myriad Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
        text-align: center;
        color: $white;
        @media (max-width: 1199px) {
          font-size: 25px;
          line-height: 30px;
        }
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .races_conut_down {
        font-family: "Myriad Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        /* identical to box height */

        text-align: center;
        color: $theme_color_v1;
        @media (max-width: 1199px) {
          font-size: 35px;
          line-height: 40px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .common_btn_v1 {
        text-transform: uppercase;
        width: 100%;
        margin-top: 15px;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
          padding: 0 10px;
          margin-top: 10px;
        }
      }
    }
  }
}

.details_games {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.leaderboard_modal {
  background: #273a5b;

  .leaderboard_table {
    max-height: 400px;
    overflow: hidden scroll;
    a {
      color: white;
      text-decoration: none;
    }
    // overflow-y: auto;
    table {
      font-size: 14px;

      thead {
        border-bottom-width: 1px !important;
        border-bottom-style: solid !important;
        border-color: rgb(34, 50, 79) !important;
        position: sticky;
        top: -1px;
        z-index: 10;
        background: #273a5b;

        tr {
          td {
            text-transform: uppercase;
            color: $common_text_color;
            border-top-width: 1px !important;
            border-top-style: solid !important;
            border-bottom-width: 1px !important;
            border-bottom-style: solid !important;
            border-color: rgb(34, 50, 79) !important;
          }
        }
      }

      tbody {
        &:not(:first-child) {
          border-top: none;
        }

        tr {
          td {
            color: $white;
            border-top-width: 1px !important;
            border-top-style: solid !important;
            border-bottom-width: 1px !important;
            border-bottom-style: solid !important;
            border-color: rgb(34, 50, 79) !important;
            padding: 10px !important;

            img {
              height: 25px;
              width: 25px;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}

.leaderboard_table {
  a {
    color: white;
    text-decoration: none;
  }
}

.leaderboard_image {
  height: 100%;
  // box-shadow: inset 0 0 10px #0d6efd;
  .race_banner {
    // clip-path: ellipse(55% 120% at 44% 50%);
    height: 100%;
    flex: 0 1 360px;
    display: grid;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 10px;
      opacity: 0.7;
    }

    p {
      position: absolute;
      bottom: 10px;
      color: white;
      font-size: 14px;
      text-align: center;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    @media (max-width: 767px) {
      clip-path: unset;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      flex: 0 1 200px;
    }
  }
}

.promotions_banner_v1 {
  .banner_img {
    .promotions_banner_img {
      width: 100%;
      display: none;
      &.desktopBanner {
        @media (min-width: 768px) {
          display: block;
        }
      }
      &.mobileBanner {
        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }
}

// leaderboard

.leaderboard_v1 {
  padding: 0px 0;
  .leaderbord_content {
    display: grid;
    grid-template-columns: 40% 60%;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    background-image: url("../assets/images/v1/banners/leaderboard-bg.png");
    // background-repeat: no-repeat;

    @media (max-width: 992px) {
      display: block;
      background-image: url("../assets/images/v1/banners/leaderboard-bg-mobile.png");
    }

    .leaderboard_games {
      .leaderboard_image {
        img {
          border-radius: 10px;
          width: 100%;
        }
      }
    }
    .leaderboard_text {
      position: relative;
      .leaderboard_text_area {
        padding: 70px 0;
        @media (max-width: 767px) {
          padding: 30px 0;
        }
        @media (min-width: 992px) and (max-width: 1400px) {
          padding: 50px 0;
        }
        .headline {
          font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 56px;
          text-align: center;
          color: $white;

          @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            padding: 0 30px;
          }
          @media (min-width: 768px) and (max-width: 992px) {
            padding: 0 50px;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 30px;
            line-height: 40px;
            padding: 0 30px;
          }
        }
        .countdown {
          .ending_text {
            font-family: "Myriad Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: $white;
            padding-top: 22px;
            @media (max-width: 767px) {
              font-size: 14px;
              padding-top: 12px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 14px;
              padding-top: 12px;
            }
          }
          .ending_clock {
            font-family: "Myriad Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 77px;
            text-align: center;
            color: $theme_color_v1;
            @media (max-width: 767px) {
              font-size: 30px;
              line-height: 35px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 30px;
              line-height: 35px;
            }
          }
        }
        .leaderbook_link {
          margin-top: 33px;
          text-align: center;
        }
        .title {
          text-align: center;
          color: $white;
        }
      }
      .info_btn {
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px;
        @media (max-width: 992px) {
          top: 15px;
          right: 15px;
        }
      }
    }
  }
}
.modal_close_custom,
.modal_close_reg {
  position: absolute;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.7);
  right: 20px;
  top: 20px;
  text-align: center;
  border-radius: 8px;
  line-height: 35px;
  z-index: 1;
  cursor: pointer;
  cursor: pointer;
  svg {
    path {
      fill: #000;
    }
  }

  &:hover {
    background: #f1980c;
    opacity: 1;
  }

  &.small {
    height: 35px;
    width: 35px;
    line-height: 30px;
  }
}
.leaderboard_table {
  .table {
    background-color: transparent !important;
    th,
    td {
      background-color: transparent !important;
    }
    thead {
      // background: #0c1f79;
      border-radius: 20px 20px 0px 0px;

      tr {
        border: none !important;

        td {
          color: $white;
          border: none !important;
          padding: 30px;
          font-weight: 700;
        }
      }
    }
    tbody {
      tr {
        border: none !important;

        td {
          color: $white;
          border: none !important;
          padding: 15px 30px;
          font-weight: 700;
        }
      }
    }
  }
}
