header {
  position: sticky !important;
  top: 0;
  z-index: 50;
  // background: $site_bg;
  background: $headerMenuBackground;
  height: 80px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-bottom: 1px solid #252637;
  padding-left: 0;

  @media (max-width: 768px) {
    padding: 10px;
    height: 70px;
  }

  .header_menu_area {
    // padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_button_group {
      display: flex;
      align-items: center;
      gap: 15px;
      height: 50px;
      @media (min-width: 768px) and (max-width: 1100px) {
        gap: 10px;
      }
      .menu_bar_area {
        cursor: pointer;
        width: 50px;
        height: 50px;
        line-height: 42px;
        text-align: center;
      }
      .casino_type {
        display: flex;
        gap: 15px;
        @media (max-width: 768px) {
          display: none;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
          gap: 10px;
        }
      }
    }
    .logo_area {
      @media (min-width: 1199px) {
        margin-left: 100px;
      }
      .with_logo {
        height: 50px;
        @media (max-width: 991px) {
          height: 30px;
        }
        @media (min-width: 992px) and (max-width: 1100px) {
          height: 40px;
        }
      }
    }

    .login_reg_btn_area {
      display: flex;
      gap: 15px;
      @media (max-width: 768px) {
        gap: 5px;
      }
      .signInBtn {
        line-height: 47px;
        height: 50px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding: 0 10px;
        }
      }
      .signUpBtn {
        line-height: 46px;
        height: 48px;
        border: 2px solid #f1980c;
        color: #fff;

        background: radial-gradient(
          235.71% 126.92% at -14.1% 50%,
          #6fcde425 0%,
          #6f7be443 100%
        );
        @media (max-width: 768px) {
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }

    .wallet_area {
      color: #fff;
      .deposit_icon {
        margin-right: 5px;
      }
    }
    .user_menu_area {
      margin-right: 50px;
      color: #fff;
      display: flex;
      align-items: center;
      margin-left: 15px;
      // gap: 15px;
      .login_reg_btn_area {
        display: flex;
        // gap: 10px;
      }
      @media (max-width: 768px) {
        margin-right: 0px;
      }
    }
  }
}
.search_block {
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
  .searchbar_searchbox {
    position: relative;
    .form-control {
      border-radius: 12px;
      border: 1px solid transparent;
      background: linear-gradient(#000000, #000000) padding-box,
        linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000) border-box;
      box-shadow: none;

      color: #6d708a;
      font-family: $fontFamily;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 184.615% */
      width: 250px;
      height: 50px;
      padding-left: 30px;
      &::placeholder {
        color: #6d708a;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #6d708a;
      }
      @media (min-width: 768px) and (max-width: 1100px) {
        width: 200px;
      }
    }
    .search-icon {
      position: absolute;
      left: 10px;
      top: 14px;
    }
  }
}
.userDropdownMenu {
  z-index: 4000;
  font-family: $fontFamily;
  margin-left: 15px;
  .dropdown-menu {
    border-radius: 0px;
    margin-top: 30px;
    background: $leftMenuBackground !important;
    // border: 1px solid $theme_color_deep;
    width: 200px;
  }

  button {
    z-index: 10000;
    text-transform: capitalize;
    background-color: #37465c4d !important;
    border: none !important;
    color: $white !important;
    &:hover,
    &:active,
    &:focus {
      color: $white !important;
      box-shadow: none !important;
      outline: none !important;
      background-color: #1099a9 !important;
    }
  }

  .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }
  /*for animation*/
  .dropdown-toggle:after {
    transition: 0.7s;
    scale: 1.4;
  }

  a {
    padding-left: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $white;
    // display: block;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    // width: fit-content;
    opacity: 1;

    img {
      width: 20px;
      margin-right: 10px;
    }
    .theme_bg {
      background: $hoverBg;
      margin-left: 20px;
    }
    // &.page_item {
    //   color: #667c83;
    //   svg {
    //     fill: #667c83;
    //   }
    // }
    .menuText {
      // display: block;
      vertical-align: middle;
    }
    svg {
      fill: $white;
      margin-right: 10px;
      // vertical-align: sub;
      path {
        fill: $white;
      }
    }
    &.active,
    &:hover {
      background: $hoverBg;
      // background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%) !important;
      color: $white !important;
      svg {
        fill: $white !important;
        svg {
          path {
            fill: $white;
          }
        }
        g {
          fill: $white !important;
          path {
            fill: $white !important;
          }
        }
      }
    }

    // &:hover {
    //   color: $white;
    //   svg {
    //     fill: $white;
    //   }
    // }
    &.logout_btn {
      .fa-power-off {
        font-size: 20px;
      }
    }
    &.small_circle {
      position: relative;
      .notification_round {
        width: 5px;
        height: 5px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        top: 18px;
        right: 12px;
      }
    }
  }
  &:hover {
    // background-color: $theme_color_deep !important;
    // a {
    //   color: $black;
    //   svg {
    //     fill: $black;
    //   }
    // }
    color: $theme_color_deep !important;
  }

  .refer_and_earn_menu {
  }
  .button_close {
    color: $white;
    opacity: 0.5;
    margin-right: 20px;
    font-size: 26px;
    float: right;
    &:hover,
    &:focus {
      color: $theme_color_deep;
      opacity: 1;
    }
  }
}
