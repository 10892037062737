.svg_icon {
  width: 20px;
  height: 20px;
  display: inline;
}
.deposit_block {
  border-radius: 5px;
  // padding: 5px 5px;
  width: 230px;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  background: rgba(55, 70, 92, 0.3);
  gap: 5px;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 155px;
  }

  .wallets_dropdown {
    display: flex;
    padding: 5px;
    background: none !important;
    border: none;
    &:after {
      display: none !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: none !important;
      box-shadow: none !important;
    }
  }
  .deposit_icon {
    border-radius: 6px;
    height: 18px;
    width: 18px;
    text-align: center;
    // line-height: 28px;
    // margin-left: 2px;
  }

  .wallet_caret {
    padding-left: 10px;
    line-height: 30px;
    @media (max-width: 767px) {
      padding-left: 0px;
    }
    .uparrow {
      display: none;
    }
    .downarrow {
      display: inline;
    }
  }
  .dropdown.show {
    .wallet_caret {
      .uparrow {
        display: inline;
      }
      .downarrow {
        display: none;
      }
    }
  }
  .deposit_amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: $white;
    padding-right: 2px;
  }
  .deposit_button {
    text-align: right;
    transition: all 500ms;
    // background: linear-gradient(180deg, #d2d2d2 0%, #828282 100%);
    background-color: #f1980c;
    border-radius: 0 5px 5px 0;
    padding: 0 5px;
    color: #000000 !important;
    opacity: 1;
    height: 40px;
    width: 100%;
    max-width: 80px;
    &.mobile_deposit_btn {
      border-radius: 5px;
    }
    &:hover {
      background: linear-gradient(180deg, #d2d2d2 0%, #828282 100%);
    }
    .deposit_btn {
      text-decoration: none;
      color: #000000 !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 38px;
      text-transform: uppercase;
      display: block;
      text-align: center;
      // box-shadow: 0px 3px 0px #5e2d94;
      border-radius: 4px;
    }
  }

  @media (max-width: 575px) {
    display: inline-block;
    // width: 100%;
    min-width: unset;
    padding: 4px 5px;
    .dropdown {
      height: 30px;
      margin-top: -6px;
      .profile_dropdown {
        padding: 0px;
      }
    }
  }

  .dropdown-menu {
    background: $leftMenuBackground;
  }
}

.login_mode {
  .depositModeButtons {
    width: 100%;
    display: flex;
    .mode_btn {
      display: flex;
      .logo {
        width: 40px;
        margin-top: -2px;
        img {
          margin: -36px -36px;
        }
      }
    }
  }
}

.modal_header_v2 {
  margin-top: 20px;
  .login_mode {
    .mode_btn {
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      padding: 30px 20px;
      text-align: center;
      margin: 20px 0;
      color: $white;
      text-decoration: none;
      background: #d2d2d2;
      display: inline-block;
      border-radius: 10px;
      width: 100%;
      height: auto;
      .subtext {
        font-size: 12px;
      }
      &:hover {
        border: 1px solid transparent;
        background: linear-gradient(#3e4057, #161721) padding-box,
          linear-gradient(to bottom, #51525f, #030304) border-box;
        color: #040f46;
        font-weight: 600;
      }
      &.active {
        border: 1px solid transparent;
        background: linear-gradient(#3e4057, #161721) padding-box,
          linear-gradient(to bottom, #51525f, #030304) border-box;
        color: #040f46;
        font-weight: 600;
      }
    }
  }
}

.big_method_button {
  display: flex;
  .method_logo {
    width: 28%;
    img,
    svg {
      max-width: 90%;
      margin-top: 33px;
      max-height: 38px;
    }
  }
  .method_main_text {
    width: 29%;
    text-align: center;
    line-height: 100%;
  }
  .method_descriptions {
    width: 43%;
    font-size: 13px;
    line-height: 100%;
    ul {
      li {
        list-style: disc !important;
        text-align: left;
      }
    }
  }
}

.wallets_menu {
  white-space: nowrap;
  padding: 15px 15px 15px 15px !important;
  margin-top: 12px;
  width: 220px;
  button {
    background: none;
    padding: 0;
    margin: 0;
  }

  input {
    color: black !important;
    font-size: 13px;
  }
  .modal_top_arrow {
    background: $leftMenuBackground;
    width: 15px;
    height: 15px;
    top: -7px;
    position: absolute;
    transform: rotate(45deg);
    display: none;
  }
  .wallets_head {
    padding-bottom: 10px;
    border-bottom: 1px solid $buttonColorOne;
    color: $white;
  }
  .wallets_balance {
    // padding: 3px 0;
    line-height: 33px;
    height: 33px;
    cursor: pointer;
    color: $white;
    font-size: 13px;
    &:hover {
      // background: #eeeeee;
      background-color: #06264c;
    }
    .currency {
      display: flex;
      .currency_icon {
        display: flex;
        padding-top: 5px;
      }
      .currency_name {
        text-align: right;
        flex-grow: 1;
      }
    }
  }
  .progress_bar {
    width: 41px;
    margin-left: auto;
    .progress {
      height: 3px;
      background: rgba(172, 115, 234, 0.4);
      .progress-bar {
        width: 0;
        background: #ac73ea;
      }
    }
  }
  .wallets_bottom {
    padding-top: 10px;
    .deposit_button {
      width: 140px;
    }
    .form-check {
      display: flex;
      align-items: center;

      .form-check-label {
        color: $white;
        font-size: 12px;
        padding-top: 1px;
      }
    }
  }
}

$walletInputBg: #202020;

$text_color: $white;
$light_grey: #ccc;
.banking_area {
  // margin: 20px auto;
  padding: 20px 0;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    padding: 10px;
  }
  .banking_heading_area {
    .common_title {
    }
    p {
      color: $text_color;
    }
  }

  .banking_list_area {
    .small_info {
      font-style: italic;
      font-size: 14px;
      margin-bottom: 5px;
      color: $text_color;
    }
    .single_banking {
      margin-bottom: 15px;
      background-color: $white;
      /*height: 100px;*/
      padding: 10px;
      ul {
        /*display: flex;*/
        align-items: baseline;
        display: grid;
        grid-template-columns: auto auto auto auto;
        @media (max-width: 767px) {
          display: block;
          text-align: center;
        }
        li {
          color: $text_color_v1;
          /*width: 25%;*/
          .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            @media (max-width: 767px) {
              display: none;
            }
          }
          .method_logo {
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
            height: 50px;
            background-repeat: no-repeat;
            border-radius: 3px;
            background-position: center;
            margin: 10px 0;
            /*-ms-flex-preferred-size: 160px;*/
            /*background-size: 75%;*/
            // background-color: $white;
            width: 160px;
            background-size: contain;
            filter: grayscale(100%);
            @media (min-width: 768px) and (max-width: 991px) {
              width: 140px;
            }
            @media (max-width: 767px) {
              float: left;
            }
          }
          .common_brown_button {
            vertical-align: middle;
          }
          &.payment_method_name {
            width: 200px;
          }

          &.min_payment_amount {
            @media (max-width: 991px) {
              padding-top: 0px;
              .helper {
                display: none;
              }
            }
          }

          .payment_content {
            vertical-align: middle;
            @media (max-width: 991px) {
              display: inline-block;
            }
            @media (max-width: 767px) {
              /*float: right;*/
            }
          }
          @media (max-width: 767px) {
            width: auto;
            text-align: left;
            /*                        .helper{
                                                    display: none;
                                                }*/

            margin-bottom: 10px;
            &.payment_logo_name {
            }
            &.min_payment_amount,
            &.payment_method_name,
            &.payment_method_name,
            &.registration_amount {
              /*                            background-color: $white;
                                                        color: #000;
                                                        padding: 10px;*/
            }
            &.registration_amount {
              text-align: center;
            }
            &:last-child {
              margin-bottom: 0px;
            }

            &.payment_logo_area {
              .payment_text_mobile {
                display: none;
                .helper {
                  display: inline-block;
                  height: 100%;
                  vertical-align: middle;
                }
                .method_logo_text {
                  vertical-align: middle;
                  display: none;
                }
                @media (min-width: 320px) and (max-width: 359px) {
                  display: none;
                }
              }
            }
            @media (min-width: 320px) and (max-width: 359px) {
              .method_logo {
                width: 100%;
              }
            }
          }

          .payment_text_mobile {
            float: left;
            display: none;
          }

          .common_brown_button {
            min-width: 145px;
          }
        }
      }
    }
  }
}

.payment_method_list {
  font-family: $fontFamily;
  .banking_area {
    .banking_list_area {
      .small_info {
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
        color: $text_color;
      }
      .single_banking {
        position: relative;
        padding: 0px;
        label {
          padding: 10px;
          width: 100%;
          margin: 0px;
          cursor: pointer;
        }
        input[type="radio"] {
          display: none;
        }

        .payment_check_mark {
          width: 20px;
          height: 20px;
          border: 2px solid #0c8bbe;
          position: absolute;
          top: 46px;
          left: 20px;
          z-index: 1;
          display: none;
        }

        input[type="radio"]:checked + label {
          border: 1px solid #0c8bbe;
          padding: 9px 10px;
          background: #cbe0e8;
        }

        .selected_method_icon {
          display: none;
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 1;
          color: $theme_color_deep;
          line-height: 15px;
          font-size: 16px;
          text-align: center;
        }

        input[type="radio"]:checked ~ label.payment_check_mark {
          .selected_method_icon {
            display: block;
          }
        }

        .payment_logo_area {
          position: relative;
          .icon_popover {
            position: absolute;
            top: 5px;
            left: 8px;
            z-index: 1;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #000;
            color: $white;
            line-height: 15px;
            font-size: 11px;
            text-align: center;
          }
        }
      }
    }
  }
}

.campaigns-wrap {
  margin: 15px 0;
  .campaigns {
    .campaign {
      display: flex;

      label.check_box {
        margin: 0px;
        margin-right: 10px;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border: 1px solid $text_color_v1;
        border-radius: 2px;
        padding-top: 2px;
        padding-left: 2px;
        .fa-check {
          display: none;
          font-size: 10px;
          color: #0c8bbe;
        }
      }
      input[type="radio"] {
        display: none;
      }
      input[type="radio"]:checked + label.check_box {
        .fa-check {
          display: block;
          margin-top: 5px;
        }
      }
    }
  }
}

.cashier_amount_lavel {
  display: inline-block;
  .radio_check_and_text_area {
    padding: 0;
    .radio_box {
      border-radius: 0px !important;
    }
  }
}

.withdrawalOptionMenu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px !important ;
  font-family: $fontFamily;
  li {
    label {
      cursor: pointer;
      padding: 3px 10px;
    }
    input[type="radio"] {
      display: none;
      // display: block;
      &:checked + label {
        border: 1px solid #0c8bbe;
      }
    }
  }
}

.cashier_modal {
  // background: #032040;
  border-radius: 8px;
  padding: 0px !important;
  a.white {
    color: white;
    text-decoration: none;
    text-align: right;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  // margin: 0 auto;
  color: $white;
  @media (max-width: 575px) {
    padding: 10px !important;
  }
  // .modal_close_reg {
  //   background-color: unset;
  //   right: 10px;
  //   top: 10px;
  // }
  .cashier_menu {
    background: linear-gradient(
      180deg,
      #3d4055 0%,
      rgba(51, 54, 71, 0) 127.81%
    );
    padding: 35px 15px 15px 15px;
    height: 100%;
  }
  .cashier_content {
    padding: 15px 15px 15px 15px;
  }
  .modal_header_v1 {
    // display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 40px;

    &.leaderboardBtn {
      justify-content: center;
      align-items: center;
      margin: 0px;

      .login_mode {
        border-radius: 50px;
        padding: 10px 5px;
        background-color: #040f46;
        .mode_btn {
          margin-left: 0px;
          border-radius: 50px;
          margin: 0 5px;
        }
      }
    }

    .login_mode {
      .depositModeButtons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .mode_btn {
          display: flex;
          .logo {
            width: 40px;
            margin-top: -2px;
            img {
              margin: -36px -36px;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      display: block;
    }
    .modal_headline {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 45px;
      color: #dddff8;
    }
    .login_mode {
      .mode_btn {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 10px;
        margin: 10px 0px;
        color: $white;
        text-decoration: none;
        background: #040f46;
        display: block;
        border-radius: 5px;
        transition: all 0.5s;
        position: relative;
        @media (max-width: 992px) {
          width: max-content;
          &:nth-child(1) {
            margin-left: 0 !important;
          }
        }

        svg {
          fill: $white;
          margin-left: 5px;
          cursor: pointer;
          path,
          ellipse {
            fill: $white;
          }
        }

        .tipQuestion {
          width: 15px;
          height: 15px;
        }

        &:hover {
          background: $buttonColorOne;
          color: $white;
          font-weight: 600;
          svg {
            cursor: pointer;
            path,
            ellipse {
              fill: #000;
            }
          }
        }
        &.active {
          background: $buttonColorOne;
          color: $white;
          font-weight: 600;
          svg {
            cursor: pointer;
            path,
            ellipse {
              fill: #000;
            }
          }
        }

        &.tipBtnArea {
          display: flex;
          justify-content: space-between;
          .ttp {
            width: 20px;
            height: 20px;
            background: url("../assets/images/kjb/icons/info-white.svg")
              no-repeat center center;
          }

          &:hover {
            .ttp {
              width: 20px;
              height: 20px;
              background: url("../assets/images/kjb/icons/info.svg") no-repeat
                center center;
            }
          }
        }
      }
      @media (max-width: 575px) {
        margin-top: 20px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow-x: scroll;
        padding-bottom: 15px;
        .mode_btn {
          width: calc(100% - 10px);
          margin: 5px;
        }
      }
      @media (max-width: 767px) {
        display: flex;
        gap: 2px;
        justify-content: space-between;
        .mode_btn {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          margin: 0px;
          padding: 5px;
          text-align: center;
          width: auto !important;
        }
      }
    }
    .in_deposit_modal {
      .mode_btn {
        background: none;
        &:hover,
        &.active {
          background: #e9a147;
          color: #000;
        }
      }
    }
  }

  .modal_header_v2 {
    margin-top: 20px;
    .login_mode {
      .mode_btn {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        padding: 30px 20px;
        text-align: center;
        margin: 20px 0;
        color: $white;
        text-decoration: none;
        // background: #06274b;
        background: radial-gradient(
            391.34% 116.55% at 86% -55.09%,
            #2f2f43 0%,
            rgba(47, 47, 67, 0) 100%
          ),
          #161721;
        border: 1px solid #252637;
        display: inline-block;
        border-radius: 10px;
        width: 100%;
        .subtext {
          font-size: 12px;
        }
        &:hover {
          background: $buttonColorOne;
          color: #040f46;
          // font-weight: 600;
        }
        &.active {
          background: $buttonColorOne;
          color: #040f46;
          // font-weight: 600;
        }
        @media (max-width: 767px) {
          padding: 15px;
        }
      }
    }
  }

  .big_method_button {
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
    .method_logo {
      width: 28%;

      img,
      svg {
        max-width: 90%;
        margin-top: 33px;
        max-height: 38px;
      }
      @media (max-width: 767px) {
        width: 100%;
        img {
          margin-top: 0 !important;
        }
      }
    }
    .method_main_text {
      width: 29%;
      padding-top: 16px;
      text-align: center;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .method_descriptions {
      width: 43%;
      font-size: 13px;
      @media (max-width: 767px) {
        width: 100%;
      }
      ul {
        li {
          list-style: disc !important;
          text-align: left;
        }
      }
    }
  }

  .buy_crypto {
    background: #040f46;
    border-radius: 20px;
    padding: 25px;
    margin-top: 35px;
    position: relative;

    @media (max-width: 768px) {
      padding: 10px;
    }
    .crypto_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_btns {
        display: flex;
        gap: 20px;
        @media (max-width: 768px) {
          gap: 10px;
        }
        a {
          line-height: 33px;
        }
      }
    }
    .crypto_points_intro {
      margin: 70px auto 50px;
      @media (max-width: 768px) {
        margin: 30px auto 20px;
      }
      .title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: $white;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 17px;
        }
        &.colored {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 700;
          font-size: 75px;
          line-height: 100px;
          color: $theme_color_v1;
          padding: 15px 0;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 70px;
          }
        }
      }
    }
    .currency_area {
      display: flex;
      justify-content: center;
      gap: 25px;
      align-items: center;
      margin-bottom: 55px;
      @media (max-width: 768px) {
        display: block;
        text-align: center;
      }
      @media (max-width: 768px) {
        margin-bottom: 30x;
      }

      .buy_crypto_currency_select {
        .select_crypto {
          background: #042d69;
          border-radius: 65px;
          color: $white;
          border: none !important;
          height: 60px;
          padding: 0 20px;
          &:focus {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
      .icon_desktop {
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
      }
      .icon_mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
          margin: 20px 0;
        }
      }
    }
    .crypto_payment_area {
      margin-top: 55px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
      .payment_title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: $white;
        margin-bottom: 15px;
      }
      .payment_methods {
        .single_method {
          background: #042d69;
          border-radius: 10px;
          padding: 8px 15px;
          text-align: center;
          margin-bottom: 45px;
          &.moonPay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 768px) {
              display: block;
            }
            .method_left {
              display: flex;
              gap: 20px;
              align-items: center;
              text-align: start;
              .method_name {
                .title {
                  font-family: $fontFamily;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  line-height: 35px;
                  color: $white;
                }
                .tag {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  font-family: $fontFamily;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 17px;
                  line-height: 35px;
                  color: $white;
                }
                p {
                  font-family: $fontFamily;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 25px;
                  color: $white;
                  margin-bottom: 0 !important;
                }
                .moonpay_drop {
                  .dropdown-toggle {
                    background: none !important;
                    border: none !important;
                    font-family: $fontFamily;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 19px;
                    // line-height: 35px;
                    color: $white;
                    padding: 0;
                    margin: 0;
                    &:focus {
                      border: none !important;
                      outline: none !important;
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
            .method_right {
              text-align: end;

              .colored {
                color: $theme_color_v1;
                font-family: $fontFamily;
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 35px;
              }
              @media (max-width: 768px) {
                text-align: start;
                margin-top: 10px;
              }
            }
          }
          .method_title {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            color: $white;
          }
        }
      }
    }

    .crypto_btn {
      margin: 40px 0;
      a {
        line-height: 50px;
      }
    }
    .powerby {
      margin: 30px 0 15px;
      display: flex;
      justify-content: center;
      gap: 16px;
      align-items: center;
      .index {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 35px;
        color: rgba(255, 255, 255, 0.43);
      }
    }
    .crypto_popup {
      position: absolute;
      background: #051047;
      border-radius: 20px;
      padding: 20px;
      bottom: 0;
      left: 0;
      width: 100%;
      // border: 2px solid rebeccapurple;
    }
  }

  .form_area {
    margin: 20px 0 10px;
    .currency_select_menu {
      &.in_vault {
        .select_crypto {
          &::after {
            margin-top: 18px !important;
          }
        }
      }
      .select_crypto {
        text-align: left;
        width: 100%;
        background: linear-gradient(#000000, #000000) padding-box,
          linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000)
            border-box !important;
        border: none;
        height: 45px;
        &:active,
        &:focus {
          background: linear-gradient(#000000, #000000) padding-box,
            linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000)
              border-box !important;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }

        &.dropdown-toggle {
          background: linear-gradient(#000000, #000000) padding-box,
            linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000)
              border-box !important;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
        &::after {
          float: right;
          margin-top: 10px !important;
          font-size: 20px;
        }
      }
      .wallets_menu.dropdown-menu {
        width: 100%;
        background: linear-gradient(#000000, #000000) padding-box,
          linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000)
            border-box !important;
        padding: 0 !important;
        color: $white;
        button {
          height: 50px;
          padding: 0;
          padding: 0 20px;
          .wallets_balance {
            padding: 0;
            color: $white;
            &:hover {
              background: none;
            }
            .amount {
              color: $white;
            }
          }
          &:hover {
            background-color: #e9a147;
            color: #000;
            .wallets_balance {
              .amount {
                color: #000;
              }
            }
          }
        }
      }
    }
    .bordered_text {
      background: linear-gradient(#000000, #000000) padding-box,
        linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #000000) border-box !important;
      margin: 20px 0;
      padding: 20px;
      border-radius: 5px;
      width: 100%;
      // width: 800px;
      // max-width: 80vw;
    }

    .input_item {
      margin-bottom: 15px;

      .in_addon_area {
        background: #06274b;
        border-radius: 10px;
      }
      .name_value {
        // background: #06274b;
        // padding: 0 20px;
        // border: none;
        // color: $white;
        // height: 45px;
        // box-shadow: none;
        // border-radius: 5px;
      }
      .currency_name {
        line-height: 38px;
        background: #e9a147;
        padding: 0 10px;
        color: #000;
      }
      .max_lavel {
        background: #e9a147;
        border: none;
        margin: 0px 0px;
        color: #000;
        border-radius: 0px 5px 5px 0;
      }
    }

    .walletAddress {
      margin: 20px 0;
      position: relative;
      .in_input_icon {
        color: #fff;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 32px;
        cursor: pointer;
      }
    }

    .qr_code.in_wallet_modal {
      text-align: center;
      .wallet_qr {
        border-radius: 4px;
        max-width: 160px;
        max-height: 160px;
      }
    }
  }
}

.fiat_selectors_modal {
  padding: 25px !important;
  .privacy_text {
    font-size: 14px;
    text-align: left;
  }
  .fiat_selectors {
    display: flex;
    .fiat_select {
      display: flex;
      padding: 10px 15px;
      white-space: nowrap;
      .radio_check_and_text_area {
        display: flex;
        gap: 10px;
        .radio_text {
        }
        .currency {
          color: white;
          display: inline-block;
          line-height: 20px;
        }
      }
    }
  }
}

#deposit_address {
  padding-right: 40px;
}
