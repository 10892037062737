.winner_section_v2 {
  margin-bottom: 100px;
  .winner_headline {
    // font-family: "Montserrat";
    // font-style: normal;
    // font-weight: 700;
    // font-size: 28px;
    // line-height: 34px;
    // color: $blue;
    // padding-bottom: 40px;

    font-family: $fontFamily;
    font-size: 50px;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    color: #ffffff;
  }
  .winner_body {
    @media (max-width: 1199px) {
      // width: 1000px;
      // padding-top: 50px;
      overflow-x: auto;
      white-space: nowrap;
    }
    @media (max-width: 768px) {
      .dataset_content_area,
      .dataset_heading_area {
        width: 850px;
      }
    }
    .winner_filterBAK {
      ul {
        margin: 40px 0 30px 0;
        padding: 0;
        // display: flex;

        // grid-template-columns: repeat(8, 1fr);
        gap: 15px;
        li {
          // float: left;
          display: inline-flex;
          list-style: none;
          text-align: center;
          font-size: 14px;
          border-bottom: 2px solid #192f3a;

          a {
            text-decoration: none;
            text-align: center;
            transition: all 0.3s ease-in-out;
            font-weight: 500;
            text-transform: capitalize;
            border-radius: 8px;
            display: block;
            padding: 8px 10px;
            color: $white;
            transition: 0.3s;
            span {
              // margin-right: 5px;
            }
            &:hover,
            &.active {
              // background: linear-gradient(
              //   180deg,
              //   #ffffff 0%,
              //   #beebff 52.08%,
              //   #ffffff 100%
              // );
              // border: 1px solid $blue;
              border-radius: 8px;
            }

            &:hover {
              transform: translate(0, -3px);
            }
          }
          &:hover {
            border-color: #e9a147;
          }
        }
      }
    }

    .winner_filter {
      ul {
        background: rgba(55, 70, 92, 0.3);
        border-radius: 10px;
        display: inline-flex;
        gap: 5px;
        padding: 6px;
        margin-bottom: 0xp;
        li {
          // float: left;
          display: inline-flex;
          list-style: none;
          text-align: center;
          font-size: 14px;
          // border-bottom: 2px solid #192f3a;

          a {
            text-decoration: none;
            text-align: center;
            transition: all 0.3s ease-in-out;
            font-weight: 500;
            text-transform: capitalize;
            border-radius: 8px;
            display: block;
            padding: 8px 10px;
            color: $white;
            transition: 0.3s;

            span {
              // margin-right: 5px;
            }
            &:hover,
            &.active {
              // background: linear-gradient(
              //   180deg,
              //   #ffffff 0%,
              //   #beebff 52.08%,
              //   #ffffff 100%
              // );
              // border: 1px solid $blue;
              border-radius: 8px;
              background-color: #1099a9;
            }

            &:hover {
              transform: translate(0, -3px);
            }
          }
          &:hover {
            border-color: #e9a147;
          }
        }
      }
    }

    .dataset {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
      // border: 1px solid;
      // padding: 5px 20px;
      align-items: center;
      position: relative;
      .win_no {
        position: absolute;
        top: -47px;
        left: calc(50% - 42px);
      }

      &.active {
        background: linear-gradient(
          180deg,
          #beebff 0%,
          #ffffff 35.42%,
          #ffffff 66.15%,
          #beebff 100%
        );
        border: 1px solid $blue;
        border-radius: 8px;
      }
      .item {
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        // line-height: 60px;
        color: #8c8c8c;
        &.win {
          color: #009966;
        }
        &.loos {
          color: #c90000;
        }
      }
    }

    .dataset_heading_area {
      .dataset {
        padding: 0 15px;
        height: 50px;
        border-radius: 5px;
        // background: #06274b;
        color: $white;
        .item {
          color: $white;
          font-family: $fontFamily;
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          font-style: normal;
          text-align: left;
          color: #ffffff;
          text-transform: uppercase;
        }
      }
    }

    .dataset_content_area {
      .dataset {
        padding: 0 15px;
        height: 50px;
        border-radius: 5px;
        margin-bottom: 5px;
        .item {
          color: $white;
          font-family: $fontFamily;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-style: normal;
          text-align: left;

          &.mult,
          &.payout {
            color: gray;
            .green {
              color: #71ee46;
            }
          }
          &.gameName {
            svg {
              width: 20px;
              fill: $white;
            }
          }

          &.playerName {
            text-transform: capitalize;
            svg {
              width: 20px;
              fill: $white;
            }
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .dataset:nth-child(odd) {
        -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        // background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%);
        background: $hoverBg;
      }

      .dataset:nth-child(even) {
        -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        // background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%);
        background: $hoverBg;
      }
    }
  }

  .winner_tabs {
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: -1px;

    @media (max-width: 767px) {
      width: 800px;
    }

    &.vip_wheels {
      .nav-item {
        .nav-link {
          &::before {
            content: "";
            background: none;
          }
          &.active {
            &::before {
              content: "";
              background: none;
            }
          }
        }
      }
    }
    .nav-item {
      .nav-link {
        border-radius: 20px 20px 0 0;

        background: $light_block_bg;
        text-decoration: none;
        color: $text_color_v1;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        margin-right: 15px;
        padding: 10px 40px 10px 15px;
        border-bottom: none !important;
        &::before {
          content: "-";
          width: 20px !important;
          height: 20px !important;
          background-image: url("../assets/images/v1/icons/trophy.svg");
          background-size: 100%;
          margin-right: 10px;
        }
        &.active {
          background: linear-gradient(
            180deg,
            rgba(220, 220, 220, 0.5) 0%,
            $white 100%
          );

          &::before {
            content: "-";
            width: 20px !important;
            height: 20px !important;
            background-image: url("../assets/images/v1/icons/trophy-active.svg");
            background-size: 100%;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .tab-content {
    overflow: hidden;
    @media (max-width: 767px) {
      width: 800px;
    }
    border: 1px solid #dddcdc;
    border-radius: 0px 20px 20px 20px;
    padding: 20px 15px;
    border-top: 0;

    .vip_wheel {
      .headline {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 32px;
        /* UI/White */
        // color: $white;
        color: $blue;
      }
      .desc {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin: 15px 0;
        /* UI/Gray */
        // color: $text_color_v1_dark;
        color: $text_color_v1;
      }

      .wheel_table {
        margin-bottom: 30px;
        .wheel_single_datarow {
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 10px 20px;
          border-bottom: 1px solid #c8c8c8;
          &.table_head {
            background: #d2d2d2;
            // color: $white;
            // text-align: center;
          }
          &:nth-child(1) {
            border-top: 1px solid #c8c8c8;
          }
          &:nth-last-child(1) {
            border-bottom: none;
          }
          .wheel_item {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: $text_color_v1;
          }
        }
      }
    }

    .item {
      border: 1px solid #dddcdc;
      background: $light_block_bg;
      border-radius: 20px;
      padding: 20px 10px;
      display: flex !important;
      gap: 10px;
      text-decoration: none;
      position: relative;
      margin: 10px !important;

      .image {
        img {
          height: 110px;
          width: 110px;
          border-radius: 20px;
        }
      }
      .game_text {
        .headline {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          line-height: 32px;
          color: $heading_color_v1;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .game_dsc {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: $text_color_v1;
          .dark {
            color: $heading_color_v1;
            font-weight: 500;
          }
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2; /* number of lines to show */
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
      }
      .game_badge {
        position: absolute;
        right: 10px;
        top: -2px;
      }
    }
  }
}
