.rewards_page_area {
  padding: 50px 0;
  .main_heading {
    color: #efefef;
    font-family: $fontFamily;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 50px;
  }
  .rewards_common_block_heading {
    color: #efefef;
    font-family: $fontFamily;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: 50px 0;
  }

  .rewards_banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    @media (max-width: 768px) {
      display: block;
    }
    .user_progress_area {
      padding: 30px;
      border-radius: 15px;
      border: 1px solid rgba(87, 119, 163, 0.5);
      background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
      @media (max-width: 768px) {
        padding: 10px;
        margin-bottom: 30px;
      }
      .top {
        display: flex;
        justify-content: space-between;
        color: #fff;
        .left {
          .stars {
            img {
              width: 30px;
              margin-right: 5px;
            }
          }
        }
        .right {
          color: #efefef;
          text-align: right;
          font-family: $fontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          img {
            width: 30px;
            vertical-align: bottom;
          }
        }
      }
      .middle {
        margin-top: 10px;
        .usernameText {
          color: #efefef;
          font-family: $fontFamily;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
          text-transform: capitalize;
        }
        .current_level {
          display: flex;
          .current_level_text {
            color: #6e7c8f;
            font-family: $fontFamily;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          img {
            width: 20px;
          }
          .number {
            color: #efefef;
            font-family: $fontFamily;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .progress_area {
        margin-top: 15px;
        .progress_top {
          display: flex;
          justify-content: space-between;
          color: #6e7c8f;
          font-size: 14px;
        }
        .progress_middle {
          margin: 5px 0;
          .progress {
            background-color: #304b70;
            height: 8px;
            .progress-bar {
              // border-radius: 15px;
              background: linear-gradient(90deg, #4cd1fe 1.33%, #cd8eff 100%);
            }
          }
        }
        .progress_bottom {
          display: flex;
          justify-content: space-between;
          color: #6e7c8f;
          font-size: 14px;
        }
      }
    }
    .countdown_area {
      padding: 10px;
      background: url("../assets/images/kjb/rewards/count-down-bg.png")
        no-repeat;
      background-size: contain;
      height: 200px;
      @media (max-width: 768px) {
        height: 150px;
      }
      .countdown_heading {
        color: #efefef;
        text-align: center;
        font-family: $fontFamily;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 55px;
        margin-bottom: 50px;
        @media (max-width: 768px) {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .countdowns {
        ul {
          padding: 0px;
          margin: 0px 0px 0px 0px;
          display: flex;
          justify-content: center;
          gap: 15px;
          li {
            border-radius: 10px;
            border: 1px solid rgba(87, 119, 163, 0.5);
            background: linear-gradient(104deg, #212337 2.39%, #1d2aaf 98%);
            box-shadow: 10px 10px 4px 0px rgba(0, 0, 0, 0.4);
            width: 80px;
            height: 80px;
            .count {
              color: #fff;
              text-align: center;
              font-family: $fontFamily;
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            .countText {
              border-radius: 10px;
              background: #1b1c2b;
              color: #efefef;
              text-align: center;
              font-family: $fontFamily;
              font-size: 12px;
              font-style: normal;
              // font-weight: 500;
              line-height: normal;
              // margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .benefits_area {
    .benefits_menu {
      margin: 30px 0;
      padding: 0px;
      display: flex;
      // gap: 30px;

      li {
        a {
          color: #6e7c8f;
          font-family: $fontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration: none;
          padding: 5px 15px;
          border-bottom: 3px solid #171824;
          position: relative;
          display: block;

          .hover {
            display: none;
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            height: 5px;
            width: 100%;
            border-radius: 15px;
            background: linear-gradient(90deg, #4cd1fe 1.33%, #cd8eff 100%);
          }
          &:hover,
          &.active {
            .hover {
              display: block;
            }
          }
        }
      }
    }
    .benefits_item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      padding: 0;
      margin: 0;
      @media (max-width: 768px) {
        display: block;
      }
      .progress {
        background-color: #304b70;
        height: 8px;
        margin: 2px 0;
        .progress-bar {
          // border-radius: 15px;
          background: linear-gradient(90deg, #4cd1fe 1.33%, #cd8eff 100%);
        }
      }
      li {
        border-radius: 15px;
        border: 1px solid rgba(87, 119, 163, 0.5);
        background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
        display: flex;
        justify-content: space-between;
        padding: 10px;
        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
        .left {
          padding: 10px;
          .heading {
            color: #efefef;
            font-family: $fontFamily;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .subHeading {
            color: #efefef;
            font-family: $fontFamily;
            font-size: 12px;
            font-style: normal;
            // font-weight: 500;
            line-height: normal;
          }
          a {
            color: #6e7c8f;
            font-family: $fontFamily;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
          }
        }
        .right {
          img {
            width: 100px;
          }
        }
        .call_to_action {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .rewards_common_block_heading {
  }
}
.vip_levels_area {
  margin: 30px 0;
  .rewards_common_block_heading {
    color: #efefef;
    font-family: $fontFamily;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: 0px 0 30px 0;
  }
  .slick-slider {
    overflow: visible;
    .slick-list {
      padding-top: 50px !important;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    // display: flex;
    justify-content: space-between;
    li {
      padding: 10px;
      .content {
        border-radius: 15px;
        border: 1px solid rgba(87, 119, 163, 0.5);
        background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
        // padding: 15px;
        text-align: center;
        // width: 200px;
        .badge {
          width: 130px;
          margin-top: -50px;
          position: relative;
          margin-left: auto;
          margin-right: auto;
          .main {
            width: 100%;
          }
          .stars {
            position: absolute;
            display: flex;
            top: 59px;
            left: auto;
            right: auto;
            width: 85px;
            justify-content: center;
            img {
              width: 8px;
            }
          }
        }
        .level_content {
          padding: 15px;
          color: #6e7c8f;
          text-align: center;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          // font-weight: 600;
          line-height: normal;
          b {
            color: #fff;
          }
          .level_heading {
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 0px;
          }
          .sub_heading {
            font-size: 12px;
            // color: #fff;
            margin-bottom: 10px;
          }
          .level_info {
            min-height: 125px;
            margin-bottom: 0px;
          }
        }
        .level_bottom {
          border-radius: 0px 0px 15px 15px;
          background: rgba(137, 137, 137, 0.3);
          // backdrop-filter: blur(2px);
          color: #fff;
          text-align: center;
          font-family: $fontFamily;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 5px 0;
        }
      }
    }
  }
}
.wheelInformaionContainer {
  .wheel_banner {
    position: relative;
    .banner_content {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        position: relative;
        transform: unset;
        padding-top: 30px;
      }
      .banner_heading {
        color: #fff;
        span {
          color: #ffa500;
        }
      }
      .banner_sub_heading {
        color: #fff;
      }
    }
    img {
      width: 100%;
    }
  }
  .how_it_works {
    text-align: center;
    margin: 30px 0;
    .heading {
      font-size: 30px;
      color: #fff;
    }
    .items {
      display: flex;
      justify-content: space-evenly;
      padding: 0px;
      margin: 30px 0px 0px 0px;
      li {
        width: 300px;
        text-align: center;
        img {
          width: 70px;
          margin-bottom: 15px;
          @media (max-width: 767px) {
            width: 50px;
            min-height: 58px;
          }
        }
        label {
          color: #fff;
          font-weight: bold;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
        p {
          color: #bebedd;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .wheel_info {
    position: relative;
    margin: 50px 0;
    background-color: #1e2032;
    // padding: 50px;
    color: #bebedd;
    border-radius: 10px;
    @media (max-width: 767px) {
      padding: 30px;
    }
  }
  .accordion {
    margin-bottom: 50px;
    .accordion-item {
      background-color: #1e2032;
      border: none;
    }
    .accordion-header {
      button {
        background-color: #1e2032;
        border: none;
        color: #fff;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &::after {
          background-image: url("../assets/images/kjb/common/angle-down.svg");
        }
      }
    }
  }
}
