.budget-calculator {
  background: $leftMenuBackground;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin: 30px auto;
  font-family: $fontFamily;
  h2,
  p {
    font-family: $fontFamily;
  }
  p {
    font-size: 14px;
  }
}

.calculator-section {
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 15px;
  }
}

.income-section,
.expenses-section {
  width: 45%;
}

.input-group {
  margin-bottom: 10px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
    }
    .currency_icon {
      .svg_icon {
        margin-top: -3px;
      }
    }
  }

  input {
    width: 100%;
    line-height: 30px;
    background: $inputBg;
    border: none;
    color: $text_color_v1_dark;
    transition-duration: 300ms;
    border: 1px solid transparent;
    border-bottom: 1px solid $inputBorderColor;
    box-shadow: 0px 1px 1px #252637;
    outline: 0;
    border-radius: 5px;
    padding: 5px 10px;
    &::placeholder {
      color: #767575;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #767575;
    }
    &[readonly] {
      opacity: 0.5 !important;
      background: $inputBg !important;
      border-bottom: 1px solid $inputBorderColor !important;
    }
    &:hover {
      background: $inputBg !important;
      // border: none !important;
      color: $text_color_v1_dark !important;
      border-bottom: 1px solid $inputBorderColor !important;
      outline: none !important;
      box-shadow: 0px 1px 1px #65b6c0 !important;
      // box-shadow: 0px 1px 1px rgba(97, 105, 114, 1) !important;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background: $inputBg !important;
      // border: none !important;
      color: $text_color_v1_dark !important;
      border-bottom: 1px solid $inputBorderColor !important;
      box-shadow: 0px 1px 1px #65b6c0 !important;
    }
    &.phoneNumber {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &.input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    option {
      background: #000 !important;
    }
  }
}

.disposable-income-section {
  margin-top: 20px;
  .total_amount {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    text-align: center;
    border-radius: 5px;
    background-color: #f1980c;
    //   border: 1px solid #ddd;
    border: none;
    font-weight: bold;
    color: #000;
    label {
      margin-bottom: 0px;
    }
  }
}
