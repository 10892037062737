.slide_games {
  margin: 50px 0;

  .slick-list {
    overflow: unset;
  }
}

.category_games_header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // justify-content: flex-end;
  margin: 10px 0;
  // align-items: center;
  padding: 0px;
  justify-content: space-between;
  @media (max-width: 480px) {
    // display: block;
  }
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  @media (max-width: 767px) {
    // display: block;
  }
  .game_headline {
    color: $white;
    font-size: 14px;
    margin-bottom: 0px;
    flex: unset;
    font-family: "Jost";
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .leftSide {
    flex: 1;
    .header_jackpot_area {
      display: flex;
      align-items: center;
      color: $white;
      .jackpot_title {
        margin-bottom: 0px;
        margin-right: 5px;
        font-size: 20px;
      }
      @media (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .rightSide {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
  }

  .headline_left {
    align-items: center;
    display: flex;
    gap: 10px;
    .game_headline {
      color: $white;
      line-height: 1;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 0;
    }
  }

  .slider_controlls {
    text-align: right;
    display: flex;
    align-items: center;
    gap: 15px;
    .commonBtn {
      border-radius: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .game_provider_dropdoun {
    position: relative;
    .provider_filter_btn {
      font-size: 0.875em;
      border: 0px;
      color: rgb(134, 139, 166);
      margin: 0px;
      padding: 12px 20px;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      background: rgb(18, 24, 51);
      width: 100%;

      .selected {
        color: rgb(74, 144, 226);
        margin: 0 5px;
      }
      &:focus {
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
      }

      &:hover {
        background: rgba(18, 24, 51, 0.8);
      }
    }

    .provider_menu {
      background: rgb(255, 255, 255);
      border-radius: 3px;
      min-width: 245px;
      max-width: 245px;
      padding: 15px 0;
      z-index: 100;
      position: absolute;
      will-change: transform;
      top: 120%;
      right: 0px;

      .provider_list {
        max-height: 300px;

        overflow-y: auto;
        .item {
          padding: 0 15px;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: rgb(240, 241, 246);
            color: rgb(10, 16, 43);
          }
          label {
            width: 100%;
            height: 100%;
            cursor: pointer;
            .checkbox_area {
              margin-right: 8px;
            }
            .game_name {
              font-size: 14px;
              font-weight: 500;
              color: $common_text_color;
            }
          }
        }
      }
    }
  }
}

.single_game {
  background: $dark_blue_bg;
  border-radius: 15px;
  // padding: 15px;
  // padding-bottom: 35px !important;
  position: relative;
  @media (max-width: 575px) {
    padding: 10px;
  }
  .games_badge {
    background: linear-gradient(90deg, rgb(251, 94, 89), rgb(231, 47, 53));
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 25px;
    cursor: pointer;
    left: 25px;
    font-size: 18px;
    color: red;
    line-height: 1.1em;
    text-transform: uppercase;
    @media (max-width: 575px) {
      top: 20px;
      left: 20px;
    }
  }
  .games_image {
    position: relative;
    // margin-bottom: 15px;
    overflow: hidden;
    border-radius: 15px;
    img {
      @media (min-width: 1400px) {
        width: 250px;
        height: 208px;
      }

      @media (max-width: 1400px) {
        width: 100%;
        // object-fit: cover;
        // height: auto;
      }
      transition: transform 0.5s;
    }
    .game_play_button {
      display: none;
      position: absolute;
      z-index: 10;
      width: 106px;
      height: 48px !important;
      line-height: 48px !important;
      font-size: 16px !important;
      top: calc(50% - 24px);
      left: calc(50% - 53px);
    }

    .jackpot_amount {
      color: $white;
      display: block;
      position: absolute;
      z-index: 10;
      width: 100%;
      text-align: center;
      height: 30px;
      line-height: 30px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 16px !important;
      top: 10px;
      left: 0px;
    }
  }
  .games_badge {
    top: -2px;
    left: 10px;
    z-index: 10;
  }
  .provider_name {
    font-size: 8px;
    text-transform: uppercase;
    font-family: "Helvetica";
  }

  .game_name {
    height: 45px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .games_rating {
    margin-top: 12px;
    display: flex;
    width: 100%;
    padding: 0 15px;
    left: 0;
    justify-content: space-between;
    align-items: center;
    font-size: 12px !important;
    position: absolute;
    bottom: 10px;
    @media (max-width: 575px) {
      padding: 0 10px;
    }
    .star {
      color: $yellow;
      font-weight: bold;
    }
    .view {
      color: #304970;
      font-weight: bold;
    }
  }
  .game_overlay {
    transition: display 2s;
    display: none;
    padding: 15px;
    text-align: center;
    // background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    .provider_name {
      font-size: 11px;
      font-weight: normal;
      @media (max-width: 575px) {
        font-size: 11px;
      }
    }

    .game_play_button {
      margin-top: 36%;
      width: 105px;
      height: 35px !important;
      line-height: 30px !important;
      font-size: 16px !important;
    }
  }
  &:hover {
    .games_image {
      img {
        // transform: scale(1.2);
        opacity: 0.6;
      }
    }
    .game_overlay {
      display: block;
    }
    .game_play_button {
      display: block;
    }

    .jackpot_amount {
      display: block;
    }
  }
}

.slide_game_list_area {
  // overflow: hidden;
  padding: 15px 0;
  padding-top: 75px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;
  .shadowOverlay {
    // display: none;
    background: rgb(16, 16, 22);
    background: linear-gradient(
      90deg,
      rgba(16, 16, 22, 0.04805672268907568) 25%,
      rgba(16, 16, 22, 1) 100%
    );
    position: absolute;
    top: 0px;
    right: 0;
    width: 100px;
    height: 100%;
    z-index: 1;
  }
  .slick-slide {
    @media (max-width: 768px) {
      width: 220px !important;
    }
  }
  .single_game_v1 {
    margin: 0 10px;
    width: 194px;
    height: 164px;
    // @media (max-width: 768px) {
    //   width: auto;
    // }
    .game_thumb {
      position: absolute;
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
    }
    .gameName {
      position: absolute;
      bottom: 10px;
      color: $white;
      text-align: center;
      width: 100%;
    }
    .hoverSelector {
      top: -76px;
    }
  }
  .slick-list {
    @media (max-width: 768px) {
      padding-left: 10px !important;
    }
  }
}

.slide_promotions {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
    li {
      img {
        // padding: 10px;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  .slick-slider .slick-list {
    padding-left: 0px !important;
  }
}

.category_games {
  &.slide_games {
    overflow: hidden;
  }
}

.single_game_v1 {
  position: relative;
  transition: 0.3s;
  background: linear-gradient(180deg, #3d4055 0%, rgba(51, 54, 71, 0) 127.81%);
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  width: 100%;
  height: 164px;

  // background-image: url("../assets/images/kjb/common/game-bg.svg");
  // background: #02090e;
  // background: linear-gradient(90deg, #02090e 0%, rgba(9, 2, 3, 0) 100%);
  .hoverSelector {
    display: none;
    border-top: 1px solid #10aed1;
    position: absolute;
    top: -48px;
    height: 1px;
    // background: red;
    width: 100%;
    box-shadow: 0px 0px 30px 2px rgba(16, 174, 209, 0.75);
    -webkit-box-shadow: 0px 0px 30px 2px rgba(16, 174, 209, 0.75);
    -moz-box-shadow: 0px 0px 30px 2px rgba(16, 174, 209, 0.75);
    svg {
      width: 100%;
    }
  }

  .game_head {
    display: none;
    // display: flex;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(180deg, #d2d2d2 0%, #828282 100%);
    border-radius: 10px 10px 0 0;
    align-items: center;
    transition: 0.3s;
    .type_icon {
      display: grid;
      grid-template-columns: 1fr 4fr;
      span {
        margin-right: 5px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #6e6e6e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 991px) {
          font-size: 9px;
        }
      }
    }
  }
  .provider_logo {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .game_thumb {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    // margin-top: -30px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }

    &:hover {
      .game_overlay {
        display: block;
        // transform: scale(1.01);
      }
      img {
        transition: transform 700ms;
        // transform: scale(1.01);
      }
    }
    .provider_name {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      color: $white;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .game_description {
    // margin-top: 15px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    border-radius: 10px;
    // transform: rotate(-90deg);
    background: #02090e;
    background: linear-gradient(360deg, #02090e 0%, rgba(9, 2, 3, 0) 100%);

    text-align: center;
    transition: 0.3s;
    width: 100%;
    .game_title {
      padding-bottom: 15px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-style: normal;
      color: #ffffff;
      text-decoration: none;
      font-family: $fontFamily;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .gameName {
    position: absolute;
    bottom: 10px;
    color: $white;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }

  .game_overlay {
    // border-radius: 20px;
    border-radius: 10px;
    // transition: display 0.3s;
    display: none;
    padding: 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;

    @media (max-width: 425px) {
      padding: 5px;
    }

    .rtp_info {
      color: $white;
      display: grid;
      grid-template-columns: 80% 20%;
      align-items: center;
      justify-content: space-between;
      .rtp_text {
        color: #fff;

        text-align: left;
        font-size: 12px;
        padding-top: 4px;
      }
      svg {
        width: 15px;
        fill: $white;
        cursor: pointer;
      }
    }
    .game_info {
      position: absolute;
      left: 0;
      bottom: 5px;
      display: grid;
      grid-template-columns: 80% 20%;
      width: 100%;
      padding: 0 10px;
      .game_name {
        text-align: left;
        // text-transform: uppercase;
        font-size: 14px;
        color: #fff;
        // text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }
      .game_provider_name {
        text-transform: uppercase;
        font-size: 10px;
        text-align: left;
        width: 100%;
        // margin-top: 5px;
        color: #93acd3;
        // color: $color_two;
        font-style: normal;
        margin-bottom: 5px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .favorite_icon {
        svg {
          fill: $buttonColorOne;
          path {
            fill: $buttonColorOne;
          }
        }
      }
    }

    .game_action {
      position: absolute;
      top: 50%; /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */
      transform: translate(-50%, -50%);
      // margin: auto;
      background-color: #0a7480;
      padding-bottom: 3px;
      border-radius: 50%;
      height: 53px;

      @media (max-width: 767px) {
        bottom: 0px;
      }

      .game_play_action {
        border: 2px solid $deep_gold_color;
        min-width: 100px;
        height: 30px;
        line-height: 25px;
        border: 1px solid $white;
        color: $white;
        /* display: -webkit-box; */
        display: -ms-flexbox;
        /* display: flex; */
        margin-bottom: 10px;
        font-size: 14px;
        margin: 0 auto;
        text-align: center;
        display: block;
        border-radius: 3px;
        @media (max-width: 425px) {
          height: 25px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        &:hover {
          background-color: $white;
          text-decoration: none;
          color: #000;
          transform: translate(0, -3px);
        }
      }
      .demo_game_play_action {
        display: block;
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
        font-size: 12px;
        min-width: 100px;
        height: 30px;
        margin: 10px auto;
        line-height: 24px;
        border-radius: 3px;

        @media (max-width: 425px) {
          height: 25px;
          margin: 5px auto;
          line-height: 20px;
        }

        &:hover {
          background-color: $white;
          text-decoration: none;
          color: #000;
        }
      }

      .game_play_icon {
        width: 50px;
        height: 50px;
        display: block;
        line-height: 52px;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        padding-left: 2px;
        padding-bottom: 5px;
        background: linear-gradient(180deg, #1099a9 11.77%, #172b54 185.64%);

        box-shadow: 0 3px 20px #109aa963, inset 0 4px 4px #ffffff5e;

        .fas {
          color: $white;
        }

        &:hover {
          // background-color: $buttonColorOne;
          transform: translate(0, -3px);
          background: linear-gradient(360deg, #1099a9 11.77%, #172b54 185.64%);
          .fas {
            color: white;
          }
        }
      }
      &:hover {
        padding-bottom: 5px;
      }
    }
  }
  .goToBtn {
    border-radius: 20px;
    color: #057383;
    text-align: center;
    font-family: $fontFamily;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    text-decoration: none;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    transition-duration: 500ms;
    transition-property: all;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    display: none;
    border: 1px solid transparent;
    background: linear-gradient(#0bfff0, #04aed3) padding-box,
      linear-gradient(to bottom, #9edeec, #04aed3) border-box;
    &:hover {
      background: #f1980c;
      background: linear-gradient(#f1980c, #ad6e08) padding-box,
        linear-gradient(to bottom, #f1980c, #ad6e08) border-box;
      // transform: translateY(-4px);
      bottom: 32px;
      transition-duration: 500ms;
      transition-property: all;
    }
  }

  &:hover {
    background-image: url("../assets/images/kjb/common/game-bg-hover.svg");
    background-size: 125%;
    background-position: center;

    .hoverSelector {
      display: block;
    }
    .game_thumb {
      // transform: scale(1.15);
    }
    .game_head {
      transform: translate(0, -12px);
    }
    .game_overlay {
      // transform: scale(1.01);
    }
    .game_description {
      transform: scale(1.01);
    }
    .goToBtn {
      transform: translate(-50%, -12px);
      transition-duration: 500ms;
      transition-property: all;
      display: block;
    }

    // .game_description {
    //   transform: translate(0, 5px);
    //   .game_title {
    //     color: $blue !important;
    //   }
    // }
  }
}

.game_play {
  margin: 40px 0;
  background: #cdcdcd;

  // background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
  .game_content {
    .game_head {
      border-radius: 10px 10px 0px 0px;
      background: $white;
      color: $heading_color_v1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      padding: 15px;
    }
    height: 728px;
    // @media (max-width: 1199px) {
    //   border-radius: 15px 15px 0 0;
    // }

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 25px $black;
    }
    img.placeholder {
      height: auto;
      opacity: 0;
    }
    iframe {
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 20 !important;
    }
  }
  .frame_bottom {
    background: $white;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    .actions_icon {
      display: flex;
      gap: 20px;
      padding-right: 20px;
      color: $common_text_color;
      align-items: center;

      .searchbar_searchbox {
        background: none !important;
        .search-icon {
          color: #b1c1d3 !important;
        }
        input {
          background: none !important;
          width: 55px;
          color: #7d8386;
          &::placeholder {
            color: transparent;
          }
          &:focus {
            width: 100%;
            &::placeholder {
              color: #7d8386;
            }
          }
        }
      }

      .currency_select {
        background: #2f4553;
        color: #b1bad3;
        padding: 3px 15px;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 15px;
      }
      i {
        cursor: pointer;
      }
      a {
        color: $common_text_color;
      }
    }
    .text_area {
      color: $common_text_color;
      font-size: 20px;
      font-family: $fontFamily;
    }

    .actions_btn {
      color: $common_text_color;

      .custom-control-input:checked ~ .custom-control-label::before {
        color: $common_text_color;
        border-color: #008a01;
        background-color: #008a01;
      }
      .custom-control-input {
        border: none;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .home_link {
        svg {
          width: 20px;
          height: 20px;
          fill: $white;
          &:hover {
            fill: $buttonColorOne;
          }
        }
      }
    }
  }
  .game_badge {
    .accordion {
      background: #0f212e !important;
      border-radius: 5px !important;
      .accordion-item {
        background: #0f212e !important;
        border-radius: 5px !important;
        .accordion-header {
          .accordion-button {
            display: block !important;
            background: #0f212e !important;
            background-color: #0f212e !important;
            &::after {
              display: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
            .header_flex {
              display: flex !important;
              justify-content: space-between !important;
              .text_area {
                color: $white;
                span {
                  color: #7d8386 !important;
                  margin-left: 15px !important;
                }
              }
              .icon_area {
                color: #b1bad3;
                background: #2f4553;
                padding: 5px 15px;
                border-radius: 8px;
                display: flex;
                gap: 5px;
                font-size: 12px;
                align-items: center;
                .golden {
                  color: #cfc36e !important;
                }
              }
            }
          }
        }
        .accordion-body {
          .tabs {
            display: flex;
            border-radius: 3rem;
            padding: 5px;
            flex-shrink: 0;
            background: #1a2c38 !important;
            width: fit-content;
            gap: 15px;
            li {
              list-style: none;
              cursor: pointer;
              padding: 10px 15px;

              text-align: center;
              border-radius: 3rem;
              color: $white;
              font-size: 13px;
              &:hover {
                background: #2f4553;
              }
              &.active {
                background: #2f4553;
              }
            }
          }
          .description {
            display: flex;
            gap: 20px;
            .game_img {
              img {
                width: 200px;
                border-radius: 10px;
              }
            }
            .details {
              .lavels {
                li {
                  list-style: none;
                  width: fit-content;
                  margin-right: 10px;
                  float: left;
                  color: #b1bad3;
                  background: #2f4553;
                  padding: 0px 8px;
                  border-radius: 10px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.theaterModeEnabled {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }

  .game_details {
    background: #282829;
    border-radius: 15px;
    padding: 15px 15px 0 15px;
    height: 100%;

    @media (min-width: 501px) and (max-width: 1199px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 1199px) {
      border-radius: 0 0 15px 15px;
    }
    .headiing {
      font-size: 20px;
      color: #f9f9f9;
      line-height: 1;
    }
    .single_details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 15px 0;

      &.players {
        align-items: center;
        grid-template-columns: unset;
        .item {
          border-right: none;
        }
      }
      .item {
        padding: 5px;
        border-right: 1px solid #4d5c79;
        text-align: center;
        @media (max-width: 500px) {
          border: none;
          // text-align: left !important;
        }
        &.active {
          background: $theme_color_light;
          .item_name {
            color: $white;
          }
          .item_value {
            color: $white;
          }
        }
        &:nth-child(1) {
          padding-left: 0;
          text-align: left;
        }
        &:nth-last-child(1) {
          padding-right: 0;
        }
        .game_block {
          display: flex;
          align-items: center;

          .game_thumb {
            img {
              width: 60px;
              height: 40px;
              border: 2px solid #374869;
              border-radius: 5px;
            }
          }
          .game_name {
            font-size: 16px;
            color: $white;
            padding-left: 10px;
            align-items: center;

            .level {
              height: 20px;
              width: 20px;
              display: block;
              border-radius: 50%;
              margin-left: 10px;
              font-size: 14px;
              color: $dark_blue_bg;
              text-align: center;
              background: $theme_color_light;

              .supper {
                color: $white;
              }
            }
          }
        }

        .item_name {
          color: #bdbdbd;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          &.white {
            color: $white;
          }
        }
        .item_value {
          color: #616672;
          font-size: 13px;
        }
      }
    }
  }
}

#game_play_window iframe {
  width: 100% !important;
  height: 100% !important;
}

.game_list_v1 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  grid-row-gap: 60px;
  margin-bottom: 50px;
  padding-top: 40px;
  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }
  @media (min-width: 768px) and (max-width: 1099px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }
  @media (min-width: 1100px) and (max-width: 1199px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
    grid-row-gap: 60px;
  }

  &.favorite_games_area {
    grid-template-columns: repeat(6, 1fr);
    @media (min-width: 576px) and (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
    }
    @media (min-width: 991px) and (max-width: 1199px) {
      grid-template-columns: repeat(5, 1fr);
      gap: 15px;
    }
  }
}

.games_filter_v1 {
  // background: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);
  padding: 15px 0px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 30px;
  .searchbar_searchbox {
    input {
      // background: linear-gradient(180deg, #e6e6e6 0%, #d2d2d2 100%);
      background-color: #1c2532;
      border: 1px solid #1c2532;
      border-radius: 5px;
      color: #8c8c8c;
      font-weight: 400;
      height: 36px;
      &::placeholder {
        color: #8c8c8c;
      }
    }
    .search-icon {
      color: #8c8c8c;
      top: 5px;
      left: 10px;
      font-size: 17px;
    }
  }
  .gameProviderDropdown {
    .providerBtn {
      width: 100%;
      // background: linear-gradient(180deg, #e6e6e6 0%, #d2d2d2 100%);
      background-color: unset;
      border: 1px solid #1c2532 !important;
      border-radius: 5px;
      color: #8c8c8c;
      height: 36px;
      justify-content: space-between;
      &:focus {
        border: 1px solid #c8c8c8 !important;
        outline: none !important;
        box-shadow: none !important;
        color: #8c8c8c !important;
      }
    }
  }

  .menus {
    overflow-x: auto;
    position: relative;

    &::after {
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #984d4d;
    }
    ul {
      margin: 15px 0 15px 0;
      padding: 0;
      display: flex;

      gap: 15px;

      // overflow-x: auto;
      // overflow-x: auto;
      // flex-direction: row;
      // grid-template-columns: repeat(8, 1fr);
      // gap: 15px;
      // @media (max-width: 575px) {
      //   grid-template-columns: repeat(4, 1fr);
      //   gap: 10px;
      // }
      // @media (min-width: 576px) and (max-width: 991px) {
      //   gap: 15px;
      // }
      // @media (min-width: 992px) and (max-width: 1199px) {
      //   gap: 10px;
      // }

      // @media (max-width: 767px) {
      //   display: grid;
      //   grid-template-columns: repeat(3, 1fr);
      //   gap: 10px;
      // }
      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      li {
        // float: left;
        list-style: none;
        text-align: center;
        font-size: 14px;
        padding: 0 5px;
        // width: 110px;
        // margin: 15px 0;
        @media (max-width: 767px) {
          // margin: 0 5px;
          // padding: 0 5px;
        }
        a {
          text-decoration: none;
          text-align: center;
          transition: all 0.3s ease-in-out;
          font-weight: 500;
          font-size: 14px;
          // background: linear-gradient(180deg, #d2d2d2 0%, #828282 100%);
          border-radius: 5px;
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 10px 12px;
          white-space: nowrap;
          // background-color: #1c2532;
          border: 1px solid #161f2c;
          // @media (max-width: 1199px) {
          //   height: 75px;
          // }

          &:hover,
          &.active {
            // background: linear-gradient(180deg, #eeeeee 0%, #beebff 100%);
            border: 1px solid $blue;
            background: linear-gradient(
              180deg,
              #1099a9 11.77%,
              #172b54 185.64%
            );
            .menu_icon {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
          &:hover {
            // transform: translate(0, -3px);
          }

          .menu_icon {
            text-align: left;
          }
          .menu_text {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: #93acd3;
            // margin-top: 2px;
            @media (max-width: 575px) {
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
.slick-next,
.slick-prev {
  z-index: 1;
}
.slick-next {
  right: -17px;
  @media (max-width: 767px) {
    right: -10px;
  }
}
.slick-prev {
  left: -18px;
  @media (max-width: 767px) {
    left: -10px;
  }
}
.gamePage {
  .container-lg {
    @media (min-width: 768px) {
      max-width: 95%;
    }
  }
}

.search_provider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 991px) {
    padding-top: 10px;
  }
  .searchbar_searchbox {
    margin: 0px;
    input {
      background-color: #1c2532;
      border: 1px solid #1c2532;
      padding-left: 30px;
      &:hover {
        background-color: #263041;
        border: 1px solid #263041;
      }
    }
  }
}
.game_part_area {
  position: relative;
  @media (min-width: 1200px) {
    .games_filter_v1 {
      position: fixed;
      // position: sticky;
      top: 70px;
      z-index: 1;
      background-color: $mainBackground;
    }
    .game_list_v1 {
      overflow: hidden;
      padding-top: 180px;
    }
  }
}

.searchbar_searchbox {
  &.sticky_search_box {
    padding: 15px;
    background-color: rgba(6, 6, 14, 0.9);
    .form-control {
      box-shadow: none;
      background: #1f2933;
      border: none;
      color: #eeeeee;
    }

    .search_suggestions {
      top: unset;
      bottom: 0px;
      height: 350px;
    }
    .caretUp {
      top: -19px;
      rotate: 180deg;
      svg {
        path {
          fill: #f9a915;
        }
      }
    }

    &.showSearchBox {
      display: block;
    }
    &.hideSearchBox {
      display: none;
    }
    .searchBottom {
      width: 242px;
      position: relative;
      z-index: 300;
      .modal_close_reg {
        height: 25px;
        width: 25px;
        line-height: 22px;
        top: -3px;
        right: 6px;
      }
      label {
        color: #fff;
        padding-left: 10px;
      }
    }
  }
}
