@-webkit-keyframes promotionSlideUp {
  to {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes promotionSlideUp {
  to {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}

.element[data-v-41cbf649] {
  padding: 0 16px;
  width: 100%;
}
.no-data {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-data__image {
  height: 165px;
  width: 165px;
}
.no-data__text {
  color: #55657e;
  font-size: 14px;
  font-weight: 500;
}
.line--interactive {
  cursor: pointer;
}
.wrap {
  overflow: hidden;
}
.animation--shadow {
  position: relative;
}
.animation--shadow:after {
  background: linear-gradient(181deg, #001a3500 21.34%, #101016 99.18%);

  bottom: 0;
  content: "";
  height: 254px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.animation .dataset {
  will-change: transform;
}

.animation--start .dataset {
  animation: shuffle-41cbf649 0.5s ease-out;
}
@keyframes shuffle-41cbf649 {
  0% {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
