$color: #213743;
$buttonBg: #1f2933;

.gameProviderDropdown {
  .input_item {
    padding: 0 10px;
    .name_value {
      box-shadow: none;
      border: 1px solid $buttonColorOne;
    }
  }
  .providerBtn {
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: $buttonBg !important;
    border-color: $color !important;
    box-shadow: none !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #263041 !important;
      border-color: #263041 !important;
    }

    .countNum {
      display: block;
      background-color: #388cea;
      padding: 0 5px;
      border-radius: 25px;
      margin: 0 5px;
      font-size: 14px;
      color: $color;
      font-weight: 600;
    }
  }

  label {
    padding: 0 10px;
    cursor: pointer;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    .checkboxDesign {
      width: 24px;
      height: 24px;
      border: 2px solid $color;
      border-radius: 3px;
      box-shadow: 0px 0px 3px #ccc;
    }
    .checkboxText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: $white;
    }
    .gameCount {
      background-color: $color;
      color: white !important;
      text-align: center;
      border-radius: 25px;
      padding: 0 3px;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked ~ .checkboxDesign {
      border-color: $color;
      background-color: $color;
      color: black;
      &::before {
        content: url("../assets/images/icons/tick-icon-white.svg");
        width: 14px;
        height: 14px;
        display: block;
        margin: -1px auto 0 auto;
      }
    }
    input[type="checkbox"]:checked ~ .gameCount {
      background-color: #388cea;
    }
  }
  .providerLists {
    height: 350px;
    overflow: auto;
    width: 250px;
    background-color: $modalBackground;
  }
  .clearListBtn {
    color: $white !important;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    display: block;
    font-weight: 600 !important;
    border-top: 1px solid #ccc;
    padding: 5px 0;
  }
}
