.feedback_content_area {
  margin: 30px 0 10px 0;
  height: 500px;
  transition: all 0.4s ease-in-out;
  @media (max-width: 767px) {
    height: 800px;
  }

  &.showFull {
    height: auto;
  }
  &.showLess {
    -webkit-mask-image: linear-gradient(0deg, transparent, #000 120px);
    mask-image: linear-gradient(0deg, transparent, #000 120px);
  }
  color: $white;

  h2 {
    font-size: 16px;
    // color: #55657e;
  }
  p {
    font-size: 14px;
    // color: #55657e;
    a {
      color: $yellowColor;
    }
  }
  .feedback_area {
    margin-left: 15px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      margin-left: 0px;
      //   margin-bottom: 0px;
    }
    h3 {
      font-size: 18px;
    }
    .your_feedback_area {
      .dropdown {
        button {
          width: 100%;
          padding: 15px;
          text-align: left;
          background-color: #4458743d !important;
          border: 1px solid transparent;
          box-shadow: 0 2px 4px #0000002e !important;
          transition: background-color 0.1s ease-in-out,
            border-color 0.1s ease-in-out;
          &::after {
            float: right;
            margin-top: 10px;
          }
        }

        .dropdown-menu {
          width: 100%;
          background-color: #102944 !important;
          a {
            color: $white;

            &:hover,
            &:focus,
            &:active {
              background-color: #001a35;
            }
          }
        }
      }
      textarea {
        background: rgba(9, 12, 17, 0.6);
        border: 1px solid rgba(147, 172, 211, 0.2);
        border-radius: 5px;
        color: var(--color_white);
        font-weight: 600;
        height: 125px;
        line-height: 1.5;
        margin-top: 8px;
        outline: 0;
        padding: 12px 16px;
        resize: none;
        width: 100%;
      }
    }

    .action_area {
      a {
        color: $yellowColor;
      }
      .commonBtn {
        border: none;
        line-height: 30px;
      }
    }
    .characterCount {
      color: #55657e;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      margin: 15px 0;
    }
  }
}
.feedback_container {
  .commonBtn {
    margin-bottom: 15px;
    background-color: #f41010 !important;
    &:hover {
      background-color: #f1980c !important;
    }
  }
}
