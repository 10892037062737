.paginationArea {
  margin: 15px 0;
  .pagination {
    .page-item {
      margin: 0 2px;
      .page-link {
        border-radius: 12px;
        border: 1px solid transparent;
        background: linear-gradient(#3e4057, #161721) padding-box,
          linear-gradient(to bottom, #51525f, #030304) border-box;
        color: #fff;
        min-width: 35px;
        &.current-page {
          //   background: #e9a147;
          background: linear-gradient(#e9a147, #452b0c) padding-box,
            linear-gradient(to bottom, #e9a147, #1c1105) border-box;
          color: #000;
        }
      }
    }
  }
}
