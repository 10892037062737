.home {
  &.dark {
    // nmcvvncm

    .access_control_area {
      .chimp-accordion {
        .accordion-body {
          background: $dashboardBg;
          color: $white;
          border: 1px solid $inputBorderColor;
          border-top: none;
          border-radius: 0 0 5px 5px;
        }
      }
    }

    // header
    .header_v1 {
      // background: linear-gradient(180deg, #0a0a0a 0%, #1e1e1e 100%);
      background: $headerMenuBackground;
      .navbar-brand {
        svg {
          path {
            fill: #beebff;

            &:nth-child(4) {
              fill: $white;
            }
            &:nth-child(1) {
              fill: #cf2a2a;
            }
          }
        }
      }
      .navbar-toggler {
        .navbar-toggler-icon {
          svg {
            path {
              stroke: #beebff;
            }
          }
        }
        .navbar-toggler-icon-close {
          svg {
            path {
              stroke: #beebff;
            }
          }
        }
      }
      .navbar_menu_v1 {
        .mobile_screen_menus {
          border-bottom: 1px solid #beebff;
          .nav-item {
            a {
              color: $text_color_v1;
              .icon {
                svg {
                  path {
                    fill: $text_color_v1_dark;
                  }
                  g {
                    path {
                      fill: $text_color_v1_dark;
                    }
                  }
                }
              }

              color: $text_color_v1_dark;
              &.active {
                color: $theme_color_light;
              }
            }
          }
        }
        .right_menus {
          .theme_mode {
            li {
              color: $heading_color_v1;
            }
          }
          li {
            .deposit_block {
              // background: $heading_color_v1 !important;
              background: linear-gradient(180deg, #282828 0%, #141414 100%);

              .deposit_amount {
                color: $text_color_v1_dark;
              }
            }
            .userDropdownMenu {
              .main_notification {
                span {
                  color: $text_color_v1_dark;
                }
              }
              .dropdown-menu {
                background: linear-gradient(180deg, #282828 0%, #141414 100%);
                border: 1px solid #333333;

                .menuText {
                  color: $text_color_v1_dark;
                }
              }
            }

            color: $text_color_v1_dark;
            svg {
              path {
                fill: $text_color_v1_dark;
              }
              circle {
                stroke: $text_color_v1_dark;
              }
            }
            .language_dropdown {
              .lang_text {
                color: $text_color_v1_dark;
              }
            }

            &.mobile_screen_full_width {
              .searchbox {
                @media (max-width: 991px) {
                  background: #333333;
                  border: none;
                }
              }
            }
            .searchbox {
              input {
                // background: #333333;
                border: none;
                color: $text_color_v1_dark;

                &:focus {
                  background: #333333;
                }
              }
            }
          }
        }
      }
    }

    // leftside

    .leftside_v1 {
      // background: $leftMenuBackground;
      // background: linear-gradient(180deg, #000000 0%, #1e1e1e 100%);
      .menus {
        border-bottom: 1px solid #beebff;
        li {
          a {
            color: $text_color_v1_dark;
            &.active {
              color: $theme_color_light;
            }
          }
        }
      }
      .theme_mode {
        li {
          color: $heading_color_v1;
        }
      }
    }

    // footer
    .footer {
      // background: #191919;
      .footer_bottom {
        .partners_logos {
          ul {
            li {
              svg {
                g {
                  fill: $white;
                }
                path {
                  fill: $white;
                }
              }
            }
          }
        }
      }
      .footer_top .links_section ul li a {
        &:hover {
          color: #fff;
        }
      }
    }

    // site background

    .right_bar_v1 {
      // background-color: $heading_color_v1;
      // background-color: #000000;
    }
    .landing {
      // background-image: url("../../assets/images/v1/banner/home-banner-dark.png");
    }

    .home_banner_v1 {
      .earn_block {
        .earn_headline {
          color: $white;
          .colored {
            color: $blue;
          }
        }
        .earn_text {
          color: $text_color_v1_dark;
        }
      }
      .header_block {
        background: linear-gradient(180deg, #282828 0%, #141414 100%);
        border: 1px solid #333333;
        .header_block_headline {
          color: $blue;
        }
        .header_text {
          color: $text_color_v1_dark;
        }
        .clock_heading {
          color: $text_color_v1_dark;
        }
        .clock {
          .item {
            .box {
              background: linear-gradient(
                180deg,
                #323232 12.5%,
                rgba(0, 0, 0, 0) 48.96%,
                #3c3c3c 91.15%
              );
              color: $text_color_v1_dark;
            }
            .text {
              color: $text_color_v1_dark;
            }
          }
        }
      }
      .offer_block {
        background-color: #323232;
        .offer_text {
          color: $text_color_v1_dark;
        }
      }
    }

    .home_slider_v1 {
      .home_slider_heading {
        .headline {
          color: $blue;
        }
        .links {
          a {
            color: #eeeeee;
            .icon {
              svg {
                path {
                  stroke: #eeeeee;
                }
              }
            }
          }
        }
      }
      .home_slider_carousel {
        .item {
          background: linear-gradient(180deg, #282828 0%, #141414 100%);
          border: none;
          .game_text {
            .headline {
              color: $white;
            }
            .game_dsc {
              color: #8c8c8c;
            }
          }
        }
      }
    }
    .games_filter_v1 {
      // background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
      // background: linear-gradient(180deg, #282828 0%, rgba(20, 20, 20, 0) 100%);

      .searchbar_searchbox {
        input {
          // background: linear-gradient(180deg, #1e1e1e 0%, #0a0a0a 100%);
          border: 1px solid #323232 !important;
          color: #8c8c8c;
        }
      }
      .gameProviderDropdown {
        .providerBtn {
          // background: linear-gradient(180deg, #1e1e1e 0%, #0a0a0a 100%);
          border: 1px solid #323232 !important;
          color: #8c8c8c;

          &:focus {
            background: linear-gradient(180deg, #1e1e1e 0%, #0a0a0a 100%);
            border: 1px solid #323232 !important;
            color: #8c8c8c;
          }
        }
      }

      .menus {
        ul {
          li {
            a {
              // background: linear-gradient(180deg, #282828 0%, #141414 100%);
              border: 1px solid #282828;
              &:hover,
              &.active {
                // background: linear-gradient(180deg, #141414 0%, #07567b 100%);
                border: 1px solid #062f54;
                .menu_text {
                  color: $white;
                }
              }

              .menu_text {
                color: #8c8c8c;
              }
            }
          }
        }
      }
    }
    .game_part_area {
      // border: 1px solid #2f3740;
    }
    .single_game_v1 {
      .game_head {
        background: #333333;
      }
      .game_description {
        .game_title {
          // color: #8c8c8c;
        }
      }
    }
    .category_games_header {
      .headline_left {
        .game_headline {
          color: $blue;
        }
      }
    }

    .bottom_banner_v1 {
      .single_item {
        background-color: #3d4751;
        border: none;
        &.blue {
          background-color: #0c8bbe;
        }
        .description {
          .heading {
            color: $white;
          }
          .desc {
            color: $text_color_v1_dark;
          }
        }
      }
    }
    .winner_section_v1 {
      .winner_body {
        .winner_filter {
          ul {
            li {
              a {
                background: linear-gradient(180deg, #1d1d1d 0%, #0c0c0c 100%);
                color: #8c8c8c;
                border: 1px solid #1d1d1d;
                border-radius: 8px;
                span {
                  margin-right: 5px;
                }
                &:hover,
                &.active {
                  background: linear-gradient(
                    180deg,
                    #141414 0%,
                    #003954 52.08%,
                    #141414 100%
                  );
                  border: 1px solid #03538c;
                  color: $white;
                }
              }
            }
          }
        }

        .dataset {
          &.active {
            background: linear-gradient(
              180deg,
              #003954 0%,
              #141414 35.42%,
              #141414 66.15%,
              #003954 100%
            );
            border: 1px solid #03538c;
          }
        }
      }

      .winner_tabs {
        border-bottom: 1px solid rgba(77, 85, 94, 1);
        .nav-item {
          .nav-link {
            background: linear-gradient(
              180deg,
              rgba(111, 121, 131, 0.5) 0%,
              rgba(71, 81, 91, 0) 100%
            );
            border: 1px solid rgba(77, 85, 94, 1);
            &.active {
              color: $text_color_v1_dark;
            }
          }
        }
      }
      .tab-content {
        border: 1px solid rgba(77, 85, 94, 1);
        .item {
          background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
          border: none;
          .game_text {
            .headline {
              color: $white;
            }
            .game_dsc {
              color: $text_color_v1_dark;
              .dark {
                color: $white;
              }
            }
          }
        }
        .vip_wheel {
          .headline {
            color: $blue;
          }
          .desc {
            color: #8c8c8c;
          }
          .wheel_table {
            .wheel_single_datarow {
              border-bottom: 1px solid #333333;
              &.table_head {
                background: #29333d;
                border-top: 1px solid #333333;
              }
              .wheel_item {
                color: #8c8c8c;
              }
            }
          }
        }
      }
    }

    .page_heading {
      .title {
        color: $buttonColorOne;
        text-shadow: 1px 1px 1px #000;
      }
      .page_nav {
        .text {
          color: $white;
          a {
            color: #beebff;
          }
        }
        .icon {
          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }
    .promotion_banner {
      background: linear-gradient(180deg, #393939 0%, #353535 100%),
        url("../assets/images/v1/promotions/promotion-banner.png");
      .text {
        color: $text_color_v1_dark;
      }
    }

    .promotion_cashback {
      // background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
      border: 1px solid rgba(97, 105, 114, 1);
      &.dropswin {
        border: none !important;
        .cashback_description {
          .heading {
            color: $blue;
          }
          .title {
            color: $blue;
          }
          .desc {
            color: $text_color_v1_dark;
          }
        }
      }
      .single_cashback {
        border-bottom: 1px solid #1e2730;
        &.freespins {
          border: none;
        }
        .cashback_description {
          .heading {
            color: $blue;
          }
          .title {
            color: $blue;
          }
          .desc {
            color: $text_color_v1_dark;
          }
        }
      }
    }

    .vip_rewards {
      background: linear-gradient(180deg, #282828 0%, #141414 100%);
      border: 1px solid #333333;
      border-radius: 10px;
      .heading {
        color: $blue;
      }
      .title {
        color: #8c8c8c;
        &.small_heading {
          color: $white;
        }
      }
      .slots {
        .heading_colored {
          color: #beebff;
        }
      }

      .reward_data {
        border-bottom: 1px solid #1e2730;
        .single_datarow {
          border-bottom: 1px solid #4e5864;
          .item {
            strong {
              color: $white !important;
            }
            color: #8c8c8c !important;
            .item_heading {
              color: $white !important;
            }
          }
        }
      }

      .transfer {
        border-bottom: 1px solid #1e2730;
        ul {
          li {
            color: #beebff;
            &.logo_svg {
              svg {
                path {
                  fill: #beebff;
                  &:nth-child(4) {
                    fill: $white;
                  }
                  &:nth-child(1) {
                    fill: #cf2a2a;
                  }
                }
              }
            }
            svg {
              path {
                fill: #beebff;
              }
            }
          }
        }
      }
      .rekeback_block {
        border-bottom: 1px solid #1e2730;
      }
      .wheel_table {
        .single_datarow {
          border-bottom: 1px solid #4e5864;
          &.table_head {
            background: #29333d;
            border-top: 1px solid #4e5864;
          }
          .item {
            color: $text_color_v1_dark;
          }
        }
      }

      &.rakeback {
        background: none;
      }
    }
    .privacy_policy {
      .title {
        color: $white;
      }
      .desc {
        color: $text_color_v1_dark;
      }
    }

    .user_dashboard {
      .credentials {
        .left_side {
          .id_no {
            .id {
              color: $text_color_v1_dark;
            }
          }
        }
        .single_item {
          .item_title {
            color: $text_color_v1_dark;
          }
        }
      }
      .page_content_area {
        color: $text_color_v1_dark;
        // background: linear-gradient(180deg, #282828 0%, #141414 100%);
        background: $dashboardContentBackground;
        border: 1px solid #252637;

        .bonus_intro {
          .intro_title {
            color: $text_color_v1_dark !important;
          }
        }
        .text {
          color: $text_color_v1_dark;
        }
        h5 {
          color: $white;
        }
        input {
          background: $inputBg;
          border: none;
          color: $text_color_v1_dark;
        }
        .payment_method_list {
          .banking_area {
            .banking_list_area {
              .single_banking {
                background: linear-gradient(180deg, #282828 0%, #141414 100%);
              }
              .payment_methods_accordions {
                .accordion {
                  .accordion-body {
                    background: $heading_color_v1;
                  }
                }
              }
            }
          }
        }

        .table_area {
          .heading_row {
            // background: linear-gradient(180deg, #282828 0%, #141414 100%);
            background: $hoverBg;
            color: $text_color_v1_dark;
          }
          .data_row {
            color: $text_color_v1_dark;
            border-top: 1px solid $heading_color_v1;
          }
        }
        .mobile_accordion {
          .accordion {
            .accordion-item {
              border-radius: 0 0 15px 15px;
              .accordion-body {
                background: $heading_color_v1;
              }
            }
          }
        }
      }
    }
    .support_panel {
      background: #111111;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      .panel_header {
        color: $white;
      }
      .close_icon {
        svg {
          path {
            stroke: $white;
          }
        }
      }
      .supportbar_tabs {
        border-bottom: none !important;
        .nav-item {
          .nav-link {
            color: #8c8c8c;
            background: linear-gradient(180deg, #282828 0%, #141414 100%);
            border: none !important;
            &.active {
              background: linear-gradient(
                180deg,
                #1e1e1e 0%,
                rgba(0, 0, 0, 0) 100%
              );

              color: #eeeeee;
              border: none;
            }
          }
        }
      }
      .tab-content {
        // border: 1px solid rgba(77, 85, 94, 1) !important;
        border: none !important;

        .accordion {
          .accordion-item {
            border-bottom: 1px solid #626f7c;
            background: none !important;
            .accordion-header {
              .accordion-button {
                color: $white;
                background: none !important;

                &:not(.collapsed) {
                  color: $white;
                  border: none !important;
                  background: #1f2933 !important;
                  &::before {
                    background-image: url("../assets/images/v1/icons/arrow-down-white.svg");
                  }
                }
                &::after {
                  display: none;
                }
                &::before {
                  height: 13px !important;
                  width: 20px !important;
                  content: "";
                  background-image: url("../assets/images/v1/icons/arrow-right-white.svg");
                  background-repeat: no-repeat;
                }
              }
            }
            .accordion-body {
              color: $text_color_v1_dark;
            }
          }
        }
        .email_us {
          color: $text_color_v1_dark;
          .contact_us_area {
            input,
            textarea {
              box-shadow: none;
              background: $heading_color_v1;
              border: none;
              color: $text_color_v1_dark;
              &:focus {
                border: 1px solid $theme_color_light;
              }
            }
          }
        }
      }
    }

    .products {
      .product_lists {
        .single_product {
          background: linear-gradient(180deg, #282828 0%, #141414 100%);

          .product_details {
            .product_heading {
              color: $text_color_v1_dark;
            }
            .product_part_info {
              color: $text_color_v1_dark;
            }
          }
        }
      }
    }
    .ticket_page {
      .page_content_area {
        background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
        color: $text_color_v1_dark;
      }
    }

    .blogs {
      .others_blogs {
        .other_single_blog {
          .paragraph {
            color: $white;
          }
        }
      }

      .blog_details {
        color: $text_color_v1_dark;
      }
    }

    .races_page {
      .winner_toggle {
        .toggle_btn {
          color: $text_color_v1_dark;
          &.active {
            color: $white;
          }
        }
      }
      .race_list {
        li {
          .race_schedule {
            color: $text_color_v1_dark;
          }
          .race_dot {
            background: $dark_block_bg;
            border: 1px solid $dark_block_bg;
          }
          .race_border {
            background: $dark_block_bg;
          }
          .race_content {
            background: $dark_block_bg;

            .race_details {
              .time {
                color: $white;
              }
              .paragraph {
                color: $text_color_v1_dark;
              }
              .title {
                color: $white;
              }
              .bonuses {
                .bonus_value {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    .game_play {
      // background: linear-gradient(180deg, #282828 0%, #141414 100%);
      // border-radius: 10px;

      background: $gamePageBackground;
      border-radius: 10px;
      border: 1px solid rgba(85, 101, 126, 0.15);

      .game_content {
        // border: 1px solid rgba(85, 101, 126, 0.15);
        .game_head {
          // background: linear-gradient(180deg, #282828 0%, #141414 100%);
          // border-radius: 10px 10px 0px 0px;
          // color: $text_color_v1_dark;
          background: $gameHeadingBg;
          border-radius: 10px 10px 0 0;
          // border: 1px solid #0c3563;
          color: #eee;
        }
      }
      .frame_bottom {
        // background: linear-gradient(180deg, #282828 0%, #141414 100%);
        // border: 1px solid #282828;

        background: $gameHeadingBg;
        // border: 1px solid #0c3563;
        .text_area {
          color: $white !important;
        }
        .actions_btn {
          color: $white !important;
        }
      }
    }

    .searchbar_searchbox {
      .search-icon {
        i {
          color: $text_color_v1_dark;
        }
      }
      .search_container {
        .caretUp {
          svg {
            path {
              fill: #3d4751;
            }
          }
        }
        .search_suggestions {
          background: $dark_block_bg;
          color: $text_color_v1_dark;
          li {
            &:hover {
              background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%);
            }
          }
        }
      }
    }
    // .license_text {
    //   color: #fff;
    // }
    // .footer_copyright_text {
    //   color: #fff;
    // }
  }
}
