.player_chat_sidebar_area {
  color: #fff;
  background: $leftMenuBackground;
  height: 100vh;
  //   position: fixed;
  // width: 200px;
  // padding-top: 15px;
  // padding: 15px;
  right: 0;

  padding: 15px;

  display: none;

  transition-property: all;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.4, 0);
  transition-duration: 0.3s;

  .player_chat_content {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.2, 1, 0.4, 0);

    transition-duration: 0.3s;
    width: 200px;
  }
}
