.home_banners {
  .home_banner_area {
    .home_banner_single {
      position: relative;
      box-shadow: 0px 0px 90px 82px rgba(15, 15, 21, 1) inset;
      -webkit-box-shadow: 0px 0px 90px 82px rgba(15, 15, 21, 1) inset;
      -moz-box-shadow: 0px 0px 90px 82px rgba(15, 15, 21, 1) inset;

      .banner_content {
        position: absolute;
        z-index: 2;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        width: 450px;
        @media (max-width: 768px) {
          left: 0px;
          width: 100%;
          padding: 15px;
        }

        .banner_heading {
          color: #fff;
          font-family: $fontFamily;
          font-size: 35px;
          font-style: normal;
          font-weight: 800;
          line-height: 40px; /* 166.667% */
          margin-bottom: 15px;
          span {
            color: #f9a215;
          }
        }
        .banner_details {
          color: var(--white, #fff);
          font-family: $fontFamily;
          font-size: 25px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 137.5% */
          @media (max-width: 768px) {
            font-size: 20px;
            line-height: 24px;
            // text-align: center;
          }
        }
        .bannerBtn {
          color: #fff;
          font-family: $fontFamily;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          // background: url("../assets/images/kjb/common/bannerBtnBG.png");
          background-size: cover;
          padding: 0 50px;
          line-height: 40px;
          height: 40px;
          // background: #f41010 !important;
          background: rgb(248, 85, 21);
          background: linear-gradient(
            90deg,
            rgba(248, 85, 21, 1) 25%,
            rgba(244, 16, 16, 1) 100%
          );
          // transition-duration: 500ms;
          //   line-height: 24px; /* 200% */
          &:hover {
            background-color: #f1980c !important;
            background: linear-gradient(90deg, #f1980c 25%, #f1980c 100%);
          }
        }
      }

      img {
        width: 100%;
        position: relative;
        z-index: -1;
      }
    }

    &.affiliateBanner {
      .home_banner_single {
        img {
          width: 100%;
          position: relative;
          z-index: 1;
        }
        .banner_heading {
          font-size: 30px;
          color: #fff;
        }
        .banner_details {
          font-weight: 500;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
  .banner_top_game_lists {
    h3 {
      color: #fff;
      font-family: $fontFamily;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 125% */
      width: 100%;
      text-align: center;
      margin: 15px 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 30px;
        position: relative;
        img {
          width: 100%;
        }
        .top_winner_count {
          position: absolute;
          top: 15px;
          border-radius: 25px;
          background: rgba(16, 16, 22, 0.4);
          backdrop-filter: blur(22.5px);
          height: 28px;
          line-height: 28px;
          padding: 0 15px;

          color: #fff;
          font-family: $fontFamily;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          text-transform: uppercase;
        }
        .top_winner_game_name {
          padding: 0 15px;
          width: 100%;
          position: absolute;
          bottom: 0px;
          left: 0px;
          background: rgba(16, 16, 22, 0.4);
          height: 50px;
          align-items: center;
          justify-content: space-between;
          //   backdrop-filter: blur(22.5px);
          display: flex;
          text-decoration: none;
          .text {
            color: #fff;
            font-family: $fontFamily;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 120% */
            text-decoration: none;
          }
          .icon {
            svg {
              margin-right: 0px;
            }
          }

          .bannerBtn {
            padding: 0px;
            border-radius: 50%;
            text-align: center;
            width: 32px;
            height: 32px;
            svg {
              margin: 0px;
            }
          }
        }
      }
    }
  }
  .banner_items {
    // display: none;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      display: block;
    }
    li {
      border-radius: 15px;
      background: #191a26;
      height: 90px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
      a {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-gap: 15px;
        align-items: center;
        text-decoration: none;
        color: #fff;
        font-family: $fontFamily;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        padding: 0 10px;

        .icon {
          padding-left: 5px;
          img {
            margin: 0 10px;
            width: 50px;
            &.spinIcon {
              width: 70px;
            }
            &.tickIcon {
              width: 45px;
            }
            &.boxIcon {
              width: 60px;
            }
          }
        }
        .text {
          text-align: center;
          color: #fff;
          font-family: $fontFamily;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 128.571% */
          // margin-top: 5px;
          display: block;
          padding-top: 5px;
        }
      }

      &.bg-one {
        background: url("../assets/images/kjb/common/banner-item-bg-one.svg")
          no-repeat;
        background-size: cover;
      }
      &.bg-two {
        background: url("../assets/images/kjb/common/banner-item-bg-two.svg")
          no-repeat;
        background-size: cover;
      }
      &.bg-three {
        background: url("../assets/images/kjb/common/banner-item-bg-three.svg")
          no-repeat;
        background-size: cover;
      }
      &.bg-four {
        background: url("../assets/images/kjb/common/banner-item-bg-four.svg")
          no-repeat;
        background-size: cover;
      }
      &.jackpotTicker {
        display: block;
        text-align: center;
        padding-top: 5px;
        .heading {
          text-align: center;
          color: #fff;
          font-family: "Impacted";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 128.571% */
          // margin-top: 5px;
          display: block;
          padding-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
