.rg_container {
  .rg_menu {
    border: 1px solid #252637;
    margin-top: 30px;
    // li {
    //   margin-bottom: 15px;

    //   a {
    //     color: $white;
    //     text-decoration: none;
    //     display: block;
    //     padding: 10px 15px;
    //     text-transform: uppercase;
    //     font-weight: 600;
    //     // line-height: 30px;
    //     transition: all 0.5s;
    //     &:hover,
    //     &.active {
    //       color: $white;
    //       text-decoration: none;
    //       background-color: #e9a147 !important;
    //       box-shadow: 0px 0.5px 0px 3px #f1980c;
    //     }
    //   }
    // }
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(12, 12, 21, 0.5);
    backdrop-filter: blur(10px);
    // border-bottom: none;
    padding: 0;
    a {
      padding: 0 15px;
      text-decoration: none;
      display: block;
      line-height: 50px;
      height: 50px;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      font-weight: 500;
      color: #fff;
      font-family: $fontFamily;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      .text {
        height: 50px;
        line-height: 50px;
        padding-top: 0;
        font-weight: 500;
        color: #fff;
        font-family: $fontFamily;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        // line-height: 24px; /* 171.429% */
        text-decoration: none;
        // padding-top: 5px;
        display: block;
      }
      &:hover,
      &.active {
        background: $leftMenuBackground;
        text-decoration: underline;
        color: #f1980c;
        .text {
          color: #f1980c;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
}
