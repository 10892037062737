.leftside_v2 {
  background: $leftMenuBackground;
  // background: radial-gradient(
  //     391.34% 116.55% at 86% -55.09%,
  //     #2f2f43 0%,
  //     rgba(47, 47, 67, 0) 100%
  //   ),
  //   #161721;
  height: calc(100vh - 80px);
  position: fixed;
  width: 245px;
  overflow-x: hidden;
  z-index: 1;

  //Left Side Animation
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;

  @media (max-width: 768px) {
    left: 0;
    padding: 0 15px;
    width: 275px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 200px;
  }
  .logo_area {
    text-align: center;
    .with_logo {
      height: 40px;
      margin-top: 10px;
    }
  }

  .leftside_logo {
    text-align: center;
    padding: 15px 0;
  }

  .topSideBar {
    background-color: $leftMenuBackground;
    padding: 15px 0;
    @media (max-width: 768px) {
      background: unset;
    }
  }
  .bottomSideBar {
    // background: $leftMenuBackground;
    height: calc(100% - 0px);
    overflow-y: auto;
    position: relative;
  }

  .promotion_button_group {
    padding: 15px;
    .bonusBtn,
    .promotionsBtn {
      .icon {
        img {
          width: 22px;
        }
      }
      transition: all 0.5s;
      // box-shadow: 0px 0px 0px 3px #f1980c;
      &:hover {
        // color: $white;
        // text-decoration: none;
        // background-color: #e9a147 !important;
        box-shadow: 0px 0.5px 0px 3px #f1980c;
      }
    }

    &.bottomBlock {
      @media (min-height: 900px) {
        position: absolute;
        bottom: 10px;
      }
    }
  }
  .menus {
    padding: 5px 0px;
    // border-bottom: 1px solid $text_color_v1_dark;
    margin: 0;
    background-color: #032040;
    overflow: hidden;
    li {
      transition: 0.3s;
      padding: 0 15px;
      a {
        text-decoration: none;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
        color: $white;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: flex;

        .icon {
          margin-right: 14px;
        }
        .arrow {
          display: none;
        }
        &.active {
          color: $buttonColorOne;
          svg {
            path {
              fill: $buttonColorOne;
            }
          }
        }
      }
      &:hover {
        transform: translate(0, -3px);
        background-color: #06264c;
      }
    }
  }

  .left_side_menu_group {
    padding: 15px;
    position: relative;
    z-index: 1;

    .dropdown-toggle {
      width: 100%;
      background-color: unset;
      border: none;
      text-align: left;

      color: #3e3e55;
      font-family: $fontFamily;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */

      &::after {
        float: right;
        margin-top: 10px;
        scale: 1.8;
      }
    }
    .btn-success:not(:disabled):not(.disabled).active:focus,
    .btn-success:not(:disabled):not(.disabled):active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: none !important;
      outline: 0 !important;
    }
    .dropdown-menu {
      width: 100%;

      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0);
      background: rgba(12, 12, 21, 0.5);
      backdrop-filter: blur(10px);
      border-bottom: none;

      a {
        padding: 0 15px;
        display: grid;
        grid-template-columns: 1fr 5fr;
        line-height: 50px;
        height: 50px;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        .text {
          color: #fff;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 171.429% */
          text-decoration: none;
          padding-top: 5px;
          display: block;
        }
      }
    }

    &.GDbtnStyle {
      .dropdown,
      .accordion {
        background: unset;
        .accordion-item {
          background: unset;
          border: none;
          .accordion-header {
            .accordion-button {
              // padding-right: 0px;
              &::after {
                // background-color: red;

                float: right;
                width: 10px;
                height: 16px;
                display: block;
                background: url("../assets/images/kjb/icons/sort-down-solid.svg")
                  no-repeat;
                background-position: left;
              }

              // &:not(.collapsed)::after {
              //   float: right;
              //   width: 20px;
              //   height: 20px;
              //   display: block;
              //   background: url("../assets/images/kjb/icons/sort-down-solid.svg")
              //     no-repeat;
              //   background-position: left;
              // }
            }
          }
        }
        .accordion-body {
          padding: 0;
          .dropdownMenu {
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0);
            background: rgba(12, 12, 21, 0.5);
            backdrop-filter: blur(10px);
            border-bottom: none;
            padding: 15px 0;
            a {
              padding: 0 15px;
              text-decoration: none;
              display: block;
              line-height: 50px;
              height: 50px;
              align-items: center;
              text-decoration: none;
              border-bottom: 1px solid rgba(255, 255, 255, 0);
              transition: all 0.5s;
              .text {
                height: 50px;
                line-height: 50px;
                padding-top: 0;
                font-weight: 500;
                color: #fff;
                font-family: $fontFamily;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                // line-height: 24px; /* 171.429% */
                text-decoration: none;
                // padding-top: 5px;
                display: block;
              }
              &:hover {
                background: $leftMenuBackground;
                text-decoration: underline;
                color: #f1980c;
                transition: all 0.5s;
                .text {
                  color: #f1980c;
                }
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
        button {
          box-shadow: none;
          border: none;
          position: relative;
          // z-index: 1;
          display: block;
          background: url("../assets/images/kjb/common/promotions-btn-bg.png");
          height: 50px;
          // line-height: 20px;
          text-align: left;
          color: $white;
          border-radius: 12px;
          font-family: $fontFamily;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          transition: all 0.5s;
          padding: 0 15px;
          // box-shadow: 0px 0px 0px 3px #f1980c;
          &:hover {
            color: $white;
            text-decoration: none;
            background-color: #e9a147 !important;
            box-shadow: 0px 0.5px 0px 3px #f1980c;
          }
          // &::before {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   // border-radius: 50%;
          //   background: red;
          //   transition: 0.5s;
          //   transform: scale(1);
          //   z-index: -1;
          // }
          .icon {
            svg {
              width: 20px;
            }
          }
          .text {
          }
        }

        .casinoGamesBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                width: 20px;
                fill: #ffa500;
              }
            }
            .text {
            }
          }
        }
        .liveCasinoGamesBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                // width: 24px;
                fill: #ffa500;
              }
            }
            .text {
            }
          }
        }
        .responsibleGamingBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                width: 26px;
                path {
                }
                // fill: #fff;
              }
            }
            .text {
            }
          }
        }
      }
      .dropdown-menu {
        a {
          display: block;

          .text {
            line-height: 50px;
            padding-top: 0;
            font-weight: 500;
          }
          &:hover {
            text-decoration: underline;
            color: #f1980c;
            .text {
              color: #f1980c;
            }
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
  .theme_mode {
    padding-left: 0;
    padding-top: 0;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) and (max-width: 1199px) {
      display: block;
    }
    li {
      background: $light_block_bg;
      border: 1px solid #dddcdc;
      border-radius: 18px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 28px;
      color: $text_color_v1;
      padding: 0px 10px 0px 4px;
      vertical-align: baseline;
      opacity: 0.6;
      transition: 0.3s;
      cursor: pointer;
      @media (min-width: 992px) and (max-width: 1199px) {
        width: fit-content;
        margin-bottom: 10px;
      }

      &.dark {
        padding: 0px 4px 0px 10px !important;
        .icon {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      .icon {
        margin-right: 10px;
        img {
          margin-top: -2px;
        }
      }

      &.active {
        background: $light_block_bg;
        opacity: 1;
      }
      &:hover {
        transform: translate(0, -3px);
      }
    }
  }

  .casino_type {
    display: flex;
    gap: 10px;
    a {
      /* Rectangle 2793 */

      // position: absolute;
      width: 140px;
      line-height: 36px;
      text-align: center;

      color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      text-decoration: none;
      .text {
        font-family: "Jost";
        font-size: 20px;
        font-weight: normal;
        line-height: 20px;
        font-style: normal;
        text-align: left;
        color: #ffffff;
        text-decoration: none;
      }
      .icon {
        img {
          width: 26px;
        }
      }
      &:hover,
      &.active {
        background: #06274b;
        border-color: #000000;
        border-width: 1px;
        border-style: dashed;
        border-radius: 5px;
      }
    }
  }
  .liveSupportBtn {
    // width: 100%;
    display: block;
    margin: 0 0px;
    line-height: 50px;
    height: auto;
  }
}

.menuBar {
  box-sizing: unset !important;

  --s: 25px; /* control the size */
  --c: orange; /* the color */

  height: var(--s);
  aspect-ratio: 1;
  border: none;
  padding: 0;
  border-inline: calc(var(--s) / 2) solid #0000;
  box-sizing: content-box;
  --_g1: linear-gradient(var(--c) 20%, #0000 0 80%, var(--c) 0) no-repeat
    content-box border-box;
  --_g2: radial-gradient(circle closest-side at 50% 12.5%, var(--c) 95%, #0000)
    repeat-y content-box border-box;
  background: var(--_g2) left var(--_p, 0px) top,
    var(--_g1) left calc(var(--s) / 10 + var(--_p, 0px)) top,
    var(--_g2) right var(--_p, 0px) top,
    var(--_g1) right calc(var(--s) / 10 + var(--_p, 0px)) top;
  background-size: 20% 80%, 40% 100%;
  position: relative;
  clip-path: inset(0 25%);
  -webkit-mask: linear-gradient(90deg, #0000, #000 25% 75%, #0000);
  cursor: pointer;
  transition: background-position 0.3s var(--_s, 0.3s),
    clip-path 0s var(--_s, 0.6s);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.menuBar:before,
.menuBar:after {
  content: "";
  position: absolute;
  border-radius: var(--s);
  inset: 40% 0;
  background: var(--c);
  transition: transform 0.3s calc(0.3s - var(--_s, 0.3s));
  width: 30px;
}

.menuBar:checked {
  clip-path: inset(0);
  --_p: calc(-1 * var(--s));
  --_s: 0s;
}
.menuBar:checked:before {
  transform: rotate(45deg);
}
.menuBar:checked:after {
  transform: rotate(-45deg);
}
.menuBar:focus-visible {
  clip-path: none;
  -webkit-mask: none;
  border: none;
  outline: 2px solid var(--c);
  outline-offset: 5px;
}

.main_body_v1.slideLeft {
  .leftside_v2 {
    .promotion_button_group {
      margin-top: 10px;
      padding: 5px !important;
      .commonBtn.bonusBtn {
        display: block;
        .text {
          display: none;
        }
      }
      .commonBtn.bonusBtn.buyCryptoBtn {
        text-align: center;
        display: block;
        .text {
          display: none;
        }
      }
    }
    .GDbtnStyle {
      &.left_side_menu_group {
        padding: 5px !important;
      }

      .accordion {
        background: unset;
        .accordion-item {
          background: unset;
          border: none;
          .accordion-header {
            .accordion-button {
              // padding-right: 0px;
              display: block;
              .text {
                display: none;
              }
              padding: 0px 3px;
              &::after {
                // background-color: red;

                float: right;
                width: 10px;
                height: 16px;
                display: block;
                background: url("../assets/images/kjb/icons/sort-down-solid.svg")
                  no-repeat;
                background-position: left;
              }

              // &:not(.collapsed)::after {
              //   float: right;
              //   width: 20px;
              //   height: 20px;
              //   display: block;
              //   background: url("../assets/images/kjb/icons/sort-down-solid.svg")
              //     no-repeat;
              //   background-position: left;
              // }
            }
          }
        }
        .accordion-body {
          padding: 0;
        }
        button {
          .icon {
            svg {
              width: 30px;
            }
          }
          .text {
          }
        }

        .casinoGamesBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                width: 20px;
                fill: #fff;
              }
            }
            .text {
            }
          }
        }
        .liveCasinoGamesBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                // width: 24px;
                fill: #fff;
              }
            }
            .text {
            }
          }
        }
        .responsibleGamingBtn {
          button {
            display: flex;
            gap: 10px;
            .icon {
              svg {
                width: 26px;
                path {
                }
                // fill: #fff;
              }
            }
            .text {
              display: none;
            }
          }
        }
      }
    }
    .buy-crypto-block.leftSideBlock {
      width: 50px;
      svg {
        margin-right: 0;
      }
    }
    .liveSupportBtn {
      svg {
        margin-right: 0;
      }
    }

    .commonBtn {
      text-align: center;
      padding: 0;
      svg {
        margin: 0;
      }
    }
  }
  .buy-crypto-block {
    padding: 0px;
    .buy-crypto-block__methods {
      display: none;
    }
    .commonBtn.bonusBtn.buyCryptoBtn {
      display: block !important;
      width: 50px;
      height: 50px;
      line-height: 48px;
      margin: 0px;
      padding: 0;
      svg {
        margin-right: 0;
      }
    }
  }
}
