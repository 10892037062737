.dashboard_container {
  min-height: 600px;
  position: relative;
  border-radius: 8px;
  overflow-y: auto;
  margin: 50px 0;
}

.user_dashboard {
  padding: 50px 0;
  .credentials {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1199px) {
      display: block;
    }
    .left_side {
      display: flex;
      gap: 50px;
      align-items: center;

      .id_no {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: $buttonColorOne;

        .id {
          font-weight: 400;
          color: $text_color_v1;
        }

        // color: $text_color_v1_dark;
      }

      .single_item {
        align-items: center;
        display: flex;
        &.switchItem {
          align-items: unset;
        }
        @media (max-width: 1199px) {
          margin-top: 20px;
        }
        .item_title {
          margin-right: 15px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: $text_color_v1;
          // margin-top: 10px;

          // color: $text_color_v1_dark;
        }

        $blue: #2196f3;

        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
          margin-bottom: 0 !important;

          & input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;

            &:before {
              position: absolute;
              content: "";
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              transition: 0.4s;
            }
          }

          input:checked + .slider {
            background-color: $blue;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px $blue;
          }

          input:checked + .slider:before {
            transform: translateX(26px);
          }

          .slider.round {
            border-radius: 34px;

            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .account_tabs {
    ul.nav-tabs {
      overflow-x: auto;
      overflow-y: hidden;
      display: grid;
      grid-template-columns: repeat(7, auto);
      align-items: center;
      justify-content: start;
      grid-gap: 5px;
      li.nav-item {
        color: #fff;
        border-radius: 12px;
        font-family: $fontFamily;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.5s;
        position: relative;
        height: 100%;
        button {
          color: #fff;
          border: none;
          border-radius: 10px 10px 0 0;
          height: 100%;
          .notification_round {
            width: 5px;
            height: 5px;
            background-color: red;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            right: 12px;

            &.orenge {
              background-color: orange;
            }
          }
        }
        .active,
        &:hover {
          background: url("../assets/images/kjb/common/promotions-btn-bg.png");
          border: none;
          border-radius: 10px 10px 0 0;
          background-size: cover;
          background-position: bottom;
        }
      }
      &::-webkit-scrollbar {
        // width: 1em;
        height: 3px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
    }
  }

  &.travelingToRight {
    position: fixed;
    left: -1120px;
    transform: translateX(-1120px);
  }
  &.cmstravelingToRight {
    position: fixed;
    left: -6000;
    transform: translateX(-6000px);
  }

  .page_content_area {
    background: $light_block_bg;
    border-radius: 5px;
    padding: 25px;
    font-family: $fontFamily;

    .progress_chart_area {
      display: flex;
      gap: 30px;
      @media (max-width: 1199px) {
        flex-direction: column;
      }
      .progress_content {
        width: 100%;
        min-height: 150px;
        background-image: linear-gradient(to bottom right, #385158, #2b3f46);
        border-radius: 10px;
        padding: 15px;
        .content_title {
          font-size: 24px;
          color: $white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            height: 30px;
          }
        }
        .content_details {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          .text_section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .balance {
              font-size: 45px;
              font-weight: 600;
              color: $white;
            }
            .date_details {
              .date_value {
                font-size: 17px;
                color: $white;
                font-weight: 600;
                align-items: center;

                &.green {
                  color: #2bc155 !important;
                  margin-top: 30px;
                }
                .chart_count {
                  height: 20px;
                  width: 20px;
                  border-radius: 5px;
                  background: #1c3222;
                  display: inline-block;
                  color: $black_bg;
                  text-align: right;
                  margin-right: 10px;
                }
              }
            }
          }
          .progress_section {
            .progress_container {
              height: 130px;
              width: 130px;
              background: $black_bg;
              border-radius: 50%;
              padding: 10px;
              @media (max-width: 400px) {
                height: 80px;
                width: 80px;
              }
              .CircularProgressbar-trail {
                stroke: #2b2b2b;
                // transition: width 2s ease-in;
              }
              .CircularProgressbar-path {
                stroke: $theme_color_light;
                stroke-linecap: unset;
                path {
                  transition: all 0.4s ease !important;
                }
              }
            }
            .progress_value {
              color: $white !important;
              font-size: 16px;
            }
          }
        }
        &.chart_area {
          border-radius: 10px;
          width: 100%;
          background-color: $black_bg;
          min-height: 150px;
          background-image: url("../assets/images/icons/graph-hql.png");
          background-repeat: no-repeat;
          background-position-x: 100%;
          background-position-y: 100%;
        }
      }
    }
    .bonus_intro {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      border-bottom: 1px solid $black_bg;
      align-items: center;
      padding-bottom: 15px;
      @media (max-width: 1199px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
      .intro_title {
        font-size: 16px;
        color: $text_color_v1;
        font-weight: 600;
      }
    }

    .table_area {
      padding: 15px 0;
      @media (max-width: 575px) {
        padding: 0px !important;
      }

      .heading_row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: $text_color_v1;
        font-weight: 600;
        padding: 15px;
        font-size: 15px;
        background: #d2d2d2;
        border-radius: 5px;
        &.transaction_history {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.game_history {
          grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr 1fr 1fr;
        }
      }
      .data_row {
        border-top: 1px solid #dcdcdc;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: $text_color_v1;
        font-weight: 400;
        padding: 5px;
        font-size: 14px;
        margin: 5px 0;
        border-radius: 5px;
        align-items: center;
        background-color: $mainBackground;
        min-height: 50px;
        @media (min-width: 576px) and (max-width: 991px) {
          font-size: 12px;
        }
        &.transaction_history {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.game_history {
          grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr 1fr 1fr;
        }

        .item {
          .table_btn {
            // height: 40px;
            font-size: 13px;
            // text-align: center;
            // padding: 0 15px;
            // background: $theme_color_light;
            // border: 1px solid #1099a9;
            color: $white;
            font-weight: 500;
            // line-height: 40px;
            display: block;
            border-radius: 5px;
            // margin: 0 5px;
            &.radius_5 {
              border-radius: 5px !important;
            }
            @media (min-width: 576px) and (max-width: 991px) {
              font-size: 12px;
            }
          }
          .action_icon {
            margin-right: 15px;
            height: 20px;
            width: 20px;
            display: inline-block;
            background: #2bc155;
            font-size: 13px;
            text-align: center;
            border-radius: 50%;
          }
          .game_img {
            width: 50px;
            height: 35px;
            border-radius: 5px;
            margin-right: 5px;
          }
        }
      }
      .mobile_accordion {
        .accordion {
          // background: $black_bg;
          border-radius: 5px;
          margin: 10px 0;
          .accordion-item {
            margin: 10px 0;
            border: none !important;
            .accordion-header {
              .accordion-button {
                padding: 10px !important;
                color: $white;
                background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%);
                &.collapsed {
                  color: $white;
                  &::after {
                    color: $white;
                  }
                  &::before {
                    color: $white;
                  }
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: none;
                }
              }
            }
            .accordion-body {
              padding: 10px !important;
              background: $light_block_bg;
              color: $text_color_v1;
              border-radius: 0 0 10px 10px;
              .accordion_content {
                @media (min-width: 768px) {
                  display: grid;
                  grid-gap: 10px;
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
        }
      }
    }
  }

  .dashboard_content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 10px;
    @media (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &.history_area {
      grid-template-columns: 2fr 4fr;
    }
    &.history_area_full {
      display: block !important;
    }
    .left_area {
      .tabName {
        margin-bottom: 20px;
        display: flex;
        gap: 20px;
        color: $white;
        align-items: center;
        @media (min-width: 768px) and (max-width: 1199px) {
          font-size: 18px !important;
          gap: 10px;
        }
        @media (min-width: 1920px) {
          font-size: 35px;
        }
        .level_img {
          width: 40px;
          @media (min-width: 768px) and (max-width: 1199px) {
            width: 20px;
          }
        }
      }
      .welcome_text {
        font-size: 15px;
        color: #828282;
        @media (min-width: 768px) and (max-width: 1199px) {
          font-size: 14px;
        }
      }

      .user_index_area {
        margin-top: 40px;

        .user_avatar_area {
          height: 170px;
          width: 170px;
          margin: 0 auto;

          img {
            border-radius: 50%;
            width: 100%;
            padding: 20px;
          }
          .sb-avatar.sb-avatar--text {
            display: none !important;
          }

          .profile_picture {
            .CircularProgressbar-trail {
              stroke: transparent;
              stroke-width: 2;
            }
            .CircularProgressbar-path {
              stroke: $theme_color_light;
              stroke-linecap: unset;
              stroke-width: 2;
              path {
                transition: all 0.4s ease !important;
              }
            }
          }
        }

        .user_avatar_medium {
          text-align: center;
          img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 0 auto;
          }
        }
        .progress_area {
          .progress {
            width: 150px;
            height: 150px;
            line-height: 150px;
            background: none;
            margin: 0 auto;
            box-shadow: none;
            position: relative;
          }
          .progress:after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 5px solid transparent;
            position: absolute;
            top: 0;
            left: 0;
          }
          .progress > span {
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
          }
          .progress .progress-left {
            left: 0;
          }
          .progress .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 3px;
            border-style: solid;
            position: absolute;
            top: 0;
          }
          .progress .progress-left .progress-bar {
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
          }
          .progress .progress-right {
            right: 0;
          }
          .progress .progress-right .progress-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
          }
          .progress .progress-value {
            width: 90%;
            height: 90%;
            border-radius: 50%;
            line-height: 135px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .progress.blue .progress-bar {
            border-color: $theme_color_deep;
          }
          .progress.blue .progress-left .progress-bar {
            animation: loading-2 1.5s linear forwards 1.8s;
          }

          @keyframes loading-1 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
          @keyframes loading-2 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(144deg);
              transform: rotate(144deg);
            }
          }
          @keyframes loading-3 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
          @keyframes loading-4 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(36deg);
              transform: rotate(36deg);
            }
          }
          @keyframes loading-5 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(126deg);
              transform: rotate(126deg);
            }
          }
          @media only screen and (max-width: 990px) {
            .progress {
              margin-bottom: 20px;
            }
          }
        }
        .user_avatar {
          img {
            margin: 10px;
            border-radius: 50%;
            border: 2px solid #332d3f;
          }
        }
        .user_name {
          margin-top: 15px;
          font-size: 25px;
          color: $white;
          text-align: center;
          line-height: 1.6;
          font-weight: 600;
          @media (min-width: 768px) and (max-width: 1199px) {
            font-size: 17px;
          }
        }
        .user_id {
          font-size: 14px;
          color: #828282;
          text-align: center;
          line-height: 1;
          font-weight: 600;
          @media (min-width: 768px) and (max-width: 1199px) {
            font-size: 12px;
          }
        }
        .currency_selectbox {
          max-width: 150px;
          margin: 50px auto 0;
          @media (min-width: 768px) and (max-width: 1199px) {
            max-width: 100px;
          }
        }
      }
    }
  }
  .button_group {
    display: flex;
    gap: 10px;
  }
  .right_area {
    background: #202020;
    border-radius: 5px;
    padding: 15px;
    &.table_area {
      padding: 15px 0;
      @media (max-width: 575px) {
        padding: 15px !important;
      }

      .heading_row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: $white;
        font-weight: 600;
        padding: 15px;
        font-size: 15px;
        &.transaction_history {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.game_history {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
      }
      .data_row {
        border-top: 1px solid $black_bg;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: $white;
        font-weight: 400;
        padding: 15px;
        font-size: 14px;
        align-items: center;

        @media (min-width: 576px) and (max-width: 991px) {
          font-size: 12px;
        }
        &.transaction_history {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.game_history {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        .item {
          .table_btn {
            height: 40px;
            font-size: 13px;
            text-align: center;
            padding: 0 15px;
            background: $theme_color_light;
            color: $black;
            line-height: 40px;
            display: block;
            border-radius: 25px;
            margin: 0 5px;
            &.radius_5 {
              border-radius: 5px !important;
            }
            @media (min-width: 576px) and (max-width: 991px) {
              font-size: 12px;
            }
          }
          .action_icon {
            margin-right: 15px;
            height: 20px;
            width: 20px;
            display: inline-block;
            background: #2bc155;
            font-size: 13px;
            text-align: center;
            border-radius: 50%;
          }
          .game_img {
            width: 70px;
            height: 50px;
            border-radius: 8px;
          }
        }
      }
      .mobile_accordion {
        .accordion {
          background: $black_bg;
          border-radius: 5px;
          margin: 10px 0;
          .accordion-item {
            background: $black_bg;
            .accordion-header {
              background: $black_bg;
              .accordion-button {
                background: $black_bg;
                padding: 10px !important;
                color: $white;
                &.collapsed {
                  color: $white;
                  &::after {
                    color: $white !important;
                  }
                  &::before {
                    color: $white !important;
                  }
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: none;
                }
              }
            }
            .accordion-body {
              padding: 10px !important;
              .accordion_content {
                @media (min-width: 401px) {
                  display: grid;
                  grid-gap: 10px;
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
        }
      }
    }

    .paginations {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;
      &.content_justify_bt {
        justify-content: space-between;
        @media (max-width: 575px) {
          flex-direction: column;
          gap: 15px;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          flex-direction: column;
          gap: 15px;
          align-items: center;
          justify-content: center;
        }
      }
      .slide_area {
        margin-left: 15px;
        .slider {
          -webkit-appearance: none;
          width: 100%;
          min-width: 200px;
          height: 4px;
          background: #4d4d4d;
          border-radius: 5px;
          outline: none;
          opacity: 0.7;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
          &:hover {
            opacity: 1;
          }
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 40px;
            height: 16px;
            background: $theme_color_light;
            cursor: pointer;
            border-radius: 15px;
          }
          &::-moz-range-thumb {
            width: 40px;
            height: 16px;
            background: $theme_color_light;
            cursor: pointer;
            border-radius: 15px;
          }
        }
      }

      .pagination_content {
        display: flex;
        width: max-content;
        justify-content: flex-end;
        @media (max-width: 575px) {
          margin: 0 auto;
        }
        gap: 15px;
        align-items: center;
        .indicator {
          color: $white;
          font-size: 13px;
          cursor: pointer;
        }
        .circles {
          display: flex;
          gap: 5px;
          font-size: 9px;
          color: #404b51;
          :nth-child(3) {
            color: $theme_color_light;
          }
          i {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.vip_level_area {
  margin-top: 15px;
  .has_menu_action {
    margin-bottom: 5px;
    color: $white;
    .fa {
      margin-right: 5px;
    }
    &:hover {
      color: $theme_color_light;
    }
  }

  .pbar {
    .progress {
      margin: 5px 0;
      background-color: $hoveColor;
      .progress-bar {
        background-color: $theme_color_light !important;
      }
    }
  }

  .next_vip_level {
    padding: 0px;
    color: $white;
    li {
      list-style: none;
    }
  }
}

.referal_area {
  .tabName {
    padding-left: 15px;
  }
  .referal_url_area {
    padding: 0 15px;
    .copy_url_area {
      position: relative;
      .in_input_icon {
        position: absolute;
        top: 6px;
        right: 15px;
        color: $white;
      }
    }
  }
}

// .refer_label {
//   font-weight: bold;
//   font-size: 20px;
// }
// .refer_copy_input {
//   position: relative;
//   input {
//     border: none;

//     color: $white;

//     font-weight: 500;
//     font-size: 18px;
//     line-height: 36px;
//     width: 100%;
//     padding: 0 15px;
//     border-radius: 5px;
//     &:focus {
//       outline: none;
//     }
//   }

//   .in_input_icon {
//     position: absolute;
//     right: 10px;
//     bottom: 5px;
//     color: $white;
//   }
// }

.main_notification {
  position: relative;
  .notification_round {
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 12px;
  }
}

.user_blocks {
  margin-top: 15px;

  .single_block {
    width: 100%;
    background: #1c1c1c;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 15px 0;

    &.twoItems {
      grid-template-columns: 50% 50%;
    }

    &.threeItems {
      grid-template-columns: 33.33% 33.33% 33.33%;
    }
    &.singleItem {
      grid-template-columns: 100%;
    }

    @media (max-width: 575px) {
      display: flex !important;
      flex-direction: column !important;
      gap: 20px !important;
      align-items: flex-start !important;
      grid-template-columns: unset !important;
      width: 100%;
    }
    &.user_area {
      grid-template-columns: 30% 50% 20%;
    }
    &.address_area {
      grid-template-columns: 30% 25% 45%;
    }
    &.cashier_currency {
      grid-template-columns: 70% 30%;
    }
    &.cashier_balance {
      grid-template-columns: 40% 60%;
    }
    &.wagerBar {
      padding: 0;
      margin: 0;
      grid-template-columns: 40% 60%;
      background-color: unset;
      margin-top: -25px;
      .item {
        border: none;
      }
    }

    &.account_activity {
      grid-template-columns: 20% 20% 20% 20% 20%;
      @media (min-width: 576px) and (max-width: 767px) {
        .item {
          border: none;
          padding: 0 3px;
        }
      }
    }
    &.filtering_block {
      @media (min-width: 576px) and (max-width: 767px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 20px;
        .item {
          border: none;
        }
      }
    }

    &.transaction_history {
      grid-template-columns: 20% 20% 20% 20% 20%;

      @media (min-width: 576px) and (max-width: 1199px) {
        grid-template-columns: 20% 20% 20% 20% 20%;
        .item {
          padding: 0 5px !important;
          border: none;
        }
      }
    }
    &.gaming_history {
      grid-template-columns: 28% 14% 14% 14% 16% 14%;
      padding-right: 0;

      @media (max-width: 575px) {
        padding-right: 10px !important;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 20px;
        .item {
          border: none;
        }
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .item {
          border: none;
          padding: 0 5px !important;
        }
      }
    }

    .item {
      position: relative;
      padding: 0 10px;
      border-right: 1px solid #4d5c79;
      align-items: center;
      @media (max-width: 575px) {
        padding: 0 !important;
        border-right: none;
        width: 100%;
      }

      &:nth-child(1) {
        // padding-left: 0;
      }
      &:nth-last-child(1) {
        // padding-right: 0;
        border-right: none;
      }
      .game_block {
        display: grid;
        grid-template-columns: 25% 45% 30%;

        .game_thumb {
          img {
            width: 100%;
            max-height: 60px;
            border: 2px solid #374869;
            border-radius: 10px;
          }
        }
        .game_name {
          font-size: 15px;
          color: $white;
          padding-left: 5px;
          align-items: center;
        }
      }
      .user_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 16px;
          color: $white;
          font-weight: 600;
          .level {
            color: #4d5c79;
            padding-left: 15px;
            @media (max-width: 575px) {
              padding-left: 10px;
            }
          }
        }
        .star {
          color: $yellow;
        }
      }
      .filter_group {
        display: flex;
        gap: 10px;
      }
      .progress_bar {
        display: grid;
        grid-template-columns: 75% 25%;
        align-items: center;
        margin: 5px 0 0 0;
        .progress {
          background: #4d5c79;
          height: 5px;
          margin-right: 20px;
          .progress-bar {
            background: $gold_color;
            align-items: end !important;
            &::after {
              content: "";
              height: 10px;
              position: absolute;
              width: 10px;
              background: #4669ff;
              border-radius: 50%;
            }
          }
        }
        .progress_value {
          padding: 2px 8px !important;
          background: $gold_color;
          border-radius: 15px;
          align-items: flex-end;
        }
      }
      .in_input_icon {
        position: absolute;
        top: 32px;
        left: calc(100% - 30px);
      }
      .input_box {
        height: 40px;
      }
    }
  }
}
.page_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 63px;
    // color: $white;
    color: $blue;

    @media (max-width: 1199px) {
      font-size: 30px;
    }
  }
  .page_nav {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    @media (max-width: 575px) {
      display: none;
    }
    a {
      text-decoration: none;
      // color: $text_color_v1_dark;
      color: $blue;
    }

    display: flex;
    color: $text_color_v1_dark;
    gap: 10px;
    .active {
      color: $heading_color_v1;
    }
  }
}

.account_activity_accordion {
  .accordion-button {
    .account_activity_heading {
      display: flex !important;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.player_message_modal {
  color: #fff;
}
.player_message_container {
  .open-modal {
    color: orange;
    font-size: 14px;
  }
  .notifications-list {
    li {
      position: relative;
      .delete_msg {
        position: absolute;
        right: 0px;
        top: 0px;
        .fa {
          color: red;
          &:hover {
            color: rgb(97, 5, 5);
          }
        }
      }
    }
  }
}
